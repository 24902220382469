import { game } from "native";

const fields = [
        "account_limit",
        "account_remaining",
        "base_price",
        "count",
        "discount_price",
        "discount_type",
        "total_limit",
        "total_remaining",
        "vip_base_price",
        "vip_discount_price"
    ];

function isNumber(val) {
    return typeof val === "number" &&
        isFinite(val) &&
        Math.floor(val) === val;
}

export default (item) => {
    const isVip = game.stats.vip && game.stats.vip.active;

    return item.quantities.map((qty) => { // aka item.listings
        // Parse item quantities into something we can actually use
        // MAPPINGS
        qty.count         = qty.quantity;
        qty.discount_type = qty.discount;
        qty.count         = parseInt(qty.count, 10);

        fields.forEach((field) =>
            // check against null is for history, where listings may be already
            // parsed due to multiple of the same item existing
            (qty[field] = (field in qty && qty[field] !== null) ? parseInt(qty[field], 10) : null)
        );

        qty.start = qty.start && new Date(qty.start);
        qty.end   = qty.end && new Date(qty.end);

        if (isVip) {
            qty.regular_price = qty.base_price;
            qty.price         = isNumber(qty.vip_discount_price) ? qty.vip_discount_price : qty.vip_base_price;
        } else {
            qty.price = isNumber(qty.discount_price) ? qty.discount_price : qty.base_price;
        }

        if (qty.discount_type) {
            qty.regular_price = isVip ? qty.vip_base_price : qty.base_price;
            // Sometimes we give away items for free and the base_price is also 0
            qty.percent_off = qty.regular_price === 0 ? 100 : ((1 - qty.price / qty.regular_price) * 100).toFixed();
        }

        // add flag to allow selling free randomchance items in restricted regions
        if (qty.percent_off === "100") {
            item.hasFree = true;
        }

        // Don't need these any more, we've merged what we needed into quantity
        delete qty.listings;

        if (qty.deliverables) {
            const deliverables = {};

            qty.deliverables.forEach((deliverable) =>
                (deliverables[deliverable.gem_store_item_id] = parseInt(deliverable.quantity, 10))
            );

            qty.deliverables = deliverables;
        }

        return qty;
    })
    .filter((qty) => (qty !== null))
    .sort((a, b) => {
        // sort by price w/ sales moved to front(left)
        const priceA = a.regular_price || a.price + 1000000;
        const priceB = b.regular_price || b.price + 1000000;

        return priceA - priceB;
    });
};
