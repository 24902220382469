import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import prompt    from "prompt/prompt";
import gems      from "elements/gems-mithril/gems";
import hover     from "hover";
import gw2Css    from "gw2.css";
import btnCss    from "button/button.css";
import promptCss from "prompt/prompt.css";

import purchaseItem      from "../../purchase.js";
import state             from "../../state.js";
import { visible, show } from "../../util/character.js";
import { byGuid }        from "../../catalog/search.js";
import itemTooltip       from "../../util/item-tooltip.js";

import openBuyGems from "../buy-gems/openBuyGems";
import icon        from "../icon/index.js";

import success from "./success.js";

import itemBtnCss from "../item-buttons/item-buttons.css";
import css        from "./purchase-prompt.css";

const i18n     = window.gemstore_strings;
const VIP_GUID = "2B0C1514-BA0A-4169-AC35-BE281FCA4DC2";

export default {
    oninit : function() {
        // Always look up the item to make sure it hasn't expired or is invalid
        this.findQuantity = (item, count, price) => {
            let quantity;

            if (!item || !count || (!price && price !== 0)) {
                return null;
            }

            item.quantities.some((qty) => {
                if (qty.count === count && qty.price === price) {
                    quantity = qty;
                }

                return quantity;
            });

            return quantity;
        };

        this.hide = () => {
            game.call("SetLoading", false);

            if (visible()) {
                requestAnimationFrame(show);
            }

            state.purchase = {
                info       : false,
                inProgress : false,
                success    : false
            };
        };

        this.purchase = () => {
            const purchase = state.purchase;
            const item     = byGuid(purchase.info.guid);

            if (purchase.info.price > game.stats.gems) {
                this.hide();

                openBuyGems();

                return;
            }

            game.call("SetLoading", true);

            state.purchase.inProgress = true;

            return purchaseItem(purchase.info)
                .then(() => {
                    game.call("SetLoading", false);

                    state.purchase.success = {
                        instant : item.type === "upgrade"
                    };

                    state.purchase.inProgress = false;

                    m.redraw();
                })
                .catch(() => this.hide());
        };
    },

    view : function(vnode) { // eslint-disable-line complexity
        const vip      = game.stats.vip;
        const purchase = state.purchase;
        const item     = purchase && purchase.info && byGuid(purchase.info.guid);
        const quantity = purchase && vnode.state.findQuantity(item, purchase.info.count, purchase.info.price);

        if (!quantity && (!purchase || !purchase.success)) {
            return null;
        }

        if (purchase.failure) {
            return m("div", prompt([
                m("h2", { class : promptCss.hd },
                    i18n.purchasing.failure.title
                ),
                m("div", { class : cx(promptCss.bd, gw2Css.serif) },
                    i18n.purchasing.failure.body
                ),
                m("div",
                    m("button", {
                        class   : btnCss.btn,
                        onclick : vnode.state.hide
                    }, i18n.purchasing.ok.button)
                )
            ]));
        }

        if (purchase.success) {
            return m(success, {
                details : purchase,
                hide    : vnode.state.hide
            });
        }

        const showUpsell = vip &&
            !vip.active &&
            quantity.vip_discount_price &&
            quantity.vip_discount_price !== quantity.price;

        return m("div", {
                // TODO: web spinner instead of native spinner
                class : cx(
                    purchase.inProgress && css.loading,
                    purchase.info && !purchase.inProgress && "faded"
                ),

                oncreate : ({ dom }) =>
                    requestAnimationFrame(() =>
                        dom.classList.remove("faded")
                    )
            },
            prompt(
                m("div", { class : css.prompt },
                    m("h2", { class : promptCss.hd }, i18n.purchasing.complete.title),

                    m("div", { class : cx(promptCss.bd, gw2Css.serif) },
                        vip && vip.active && m("div", { class : css.vipApplied },
                            i18n.vip.discount
                        ),
                        m("div", { class : css.item },
                            icon(item, {
                                attrs : {
                                    onmouseover : hover(itemTooltip.bind(null, item)),
                                    onmouseout  : hover(itemTooltip.bind(null, item))
                                }
                            }),

                            m("div", { class : css.bd },
                                m("div", { class : css.name }, item.name),

                                m("div", { class : css.price },
                                    quantity.count,

                                    m("span", { class : css.for }, i18n.purchasing.complete.for),

                                    m("span", { class : css.gemsContainer },
                                        gems(quantity.price),

                                        quantity.regular_price ?
                                            m("div", { class : css.regularPrice }, quantity.regular_price) :
                                            null
                                    )
                                )
                            )
                        ),

                        showUpsell ?
                            m("div", {
                                class   : css.vipUpgrade,
                                onclick : () => {
                                    // TODO: test this once categories are set up in chinadev
                                    const vipItem = byGuid(VIP_GUID);

                                    state.highlightItem(vipItem, vipItem.categories[0]);
                                    vnode.state.hide();
                                }
                            },

                            m("div", { class : css.upgradeText }, i18n.vip.upgrade),

                            m("div", { class : css.gemsContainer },
                                m("button", { class : item.percent_off ? itemBtnCss.buttonSale : itemBtnCss.button },
                                    gems(quantity.vip_discount_price, { class : css.upgradeBtn })
                                ),

                                m("span", { class : css.regularPriceBlue }, quantity.price)
                            )
                        ) :
                        null
                    ),
                    m("div",
                        m("button", {
                                class    : cx(btnCss.btn, css.confirmBtn),
                                onclick  : vnode.state.purchase,
                                disabled : state.purchase.inProgress
                            },
                            i18n.purchasing.complete.button
                        ),

                        m("button", {
                                class   : btnCss.btn,
                                onclick : vnode.state.hide
                            },
                            i18n.flow.button.cancel
                        )
                    )
            ), "purchase-prompt", vnode.state.hide)
        );
    }
};
