import m from "mithril";

import { game } from "native";

import object from "object";

import guid from "./guid";

const shoppingSession = guid();

const build = m.buildQueryString || m.route.buildQueryString;

export default function(data) {
    const merged = object.merge({
        s    : game.stats.sessionId,
        gems : game.stats.gems,
        shoppingSession
    }, data);

    // Only send what's needed - server will provide appropriate defaults
    object.each(merged, (val, key) => {
        if (val === undefined || val === null) {
            delete merged[key];
        }
    });

    /* program:!live */
    // Do some stupid shit so release mode doesn't strip this log
    localStorage.showLogs && window.console.log("Event Log: ", merged);
    /* /program:!live */

    // exchange1 is mithril 0.2.4...
    return m.request({
        url : `/log?${build(merged)}`
    });
}
