import m from "mithril";

import { game } from "native";

import { hide, visible, show } from "./util/character.js";
import preview                 from "./util/show-preview.js";

import { parents } from "./categories.js";

const state = {
    showingCached : false,

    // Have to track our own history because window.history.back() doesn't work :(
    history : [],

    purchases  : [],
    catalog    : {},
    popup      : false,
    timePicker : false, // for previewing
    inPackage  : [],    // can preview sold out items if in a pkg

    search : {}, // criteria, perhaps categories

    searchResults   : [],
    carouselResults : [],
    featuredResults : [],
    extraResults    : [],   // "you may also like..."
    resultsChanged  : true, // for animation timing
    clickedItem     : null, // yellow highlight and scroll

    carousel : {
        index     : 0,
        prevIndex : 0
    },

    purchase : {
        info       : false,
        inProgress : false,
        success    : false
    },

    noResultsFiller : [],
    previewing      : false, // paperdoll
    flyout          : null,  // categories tooltip
    flyoutResults   : null,

    gifting : {
        contacts : [],
        order    : {},
        success  : false,
        failure  : false
    },

    exchange : {
        gold : "",
        gems : ""
    },

    // if Gemstore should immediately open the Buy Gems Window
    openBuyGems : false
};

const set = m.route.set;

// Well, this is diabolical...
// Doing this because Coherent UI doesn't support window.history.back(), so have to track our own history stack :(
// TODO: this isn't true in updated coherent or cef, remove
m.route.set = function(route, ignore) {
    if (!ignore) {
        state.push(route);
    }

    set(route);
};

state.setFlyout = function(value) {
    state.flyout = value;

    if (value) {
        hide();
    }

    if (!value && visible() && !state.purchase.info && !state.popup) {
        show();
    }
};

// purchase history
state.onHistory = function() {
    const route = m.route.get() || document.location.pathname;

    return route === "/history" || route.indexOf("/history?") === 0;
};

state.onHome = function() {
    const route = m.route.get() || document.location.pathname;

    return route === "/" || route.indexOf("/?") === 0;
};

state.showPopup = function(content) {
    // Hide character model, but don't adjust previewing state
    hide();
    game.call("HideTextTooltip");
    state.popup = content;
};

state.hidePopup = function() {
    state.popup = false;

    // Restore character model only if it was showing before the popup
    if (visible()) {
        requestAnimationFrame(show);
    }
};

state.goToCategory = (item) => {
    const categories = parents(item.categories[0]);

    m.route.set(`/category/${categories.join("/")}`);
};

state.highlightItem = (item, category, extraParams) => {
    let categories = category,
        foundCat;

    if (!item) {
        return null;
    }

    // Fake gemstore banner items can point to other items
    if (item.gemStoreBannerItem) {
        item = state.catalog[item.gemStoreBannerItem];
    }

    const params = extraParams ? `&${m.buildQueryString(extraParams)}` : "";

    if (!Array.isArray(category)) {
        if (window.gemstoreCategories[category] && parents(category)) {
            // Use category provided if it leads to root
            categories = parents(category);
        } else {
            // The exact category wasn't passed in, so go find a parent category
            // that will work, then only use a top-level category like style/utility
            categories = [
                item.categories.some((cat) => {
                    if (parents(cat)) {
                        foundCat = cat;

                        return true;
                    }

                    return false;
                }) && parents(foundCat)[0]
            ];
        }
    }

    if (item.previewable) {
        preview(item);
    }

    if (item.isPackage) {
        return m.route.set(`/item/${item.guid}?categories=${categories.join(",")}${params}`);
    }

    // Beware nested template literal
    return m.route.set(`/category/${categories.join("/")}?guid=${item.guid}${params}`);
};

state.push = () => {
    // this data needs to be static, a point in history
    // props on `location` are always the current location
    // use the static string `href` instead of `document.location`
    state.history.push(document.location.href);

    state.history = state.history.slice(-20);
};

state.pop = () => {
    if (state.history.length) {
        // Replace the src parameter when navigating backwards so event logs don't lie
        const url    = new URL(state.history.pop());
        const search = m.parseQueryString(url.search);

        // CEF only, COH doesn't have `searchParams`
        // url.searchParams.set("src", "back");

        if (state.search.package) {
            // url.searchParams.set("guid", state.search.package);
            search.guid = state.search.package;

            url.search = `?${m.buildQueryString(search)}`;
        }

        // Mithril using hash routing
        const mithrilPath = url.toString().split("#!")[1];

        // this was monkey patched! `true` means "route but don't push to history"
        m.route.set(mithrilPath, true);
    }
};

// stats not available on startup
state.isSteamUser = () => game.stats.provider === "Steam";

export default state;
