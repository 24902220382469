import { game } from "native";

const keyList  = {};
const injected = {};

export default {
    register : function(id, keys) {
        if (typeof id !== "number" || !Array.isArray(keys) || !keys.length) {
            return;
        }

        keyList[id] = keys;
    },
    inject : function(id) {
        const keys = keyList[id];

        if (typeof id !== "number" || id in injected || !Array.isArray(keys) || !keys.length) {
            return;
        }

        keys.forEach(function(key) {
            game.call("SetTextEncryptionKey", parseInt(key.id, 10), key.password);
        });

        injected[id] = true;
    }
};
