import m from "mithril";

import { game } from "native";

function stsNative(msg, opts) {
    // Intentionally sending an empty object when there is no body
    // otherwise, the sts message fails to parse
    msg.body      = JSON.stringify(msg.body || {});
    msg.headers.c = "application/json";

    return game.call("StsRequest", msg)
        .then((data) => (data.bodyRaw ? JSON.parse(data.bodyRaw) : data))
        .catch((error) => {
            const e = new Error("STS Error");

            /* program:!live */
            Object.keys(error).forEach(function(key) {
                e[key] = error[key];
            });

            e.request = msg;
            /* /program:!live */

            if (opts && opts.errorCode) {
                e.code = error.code;
            }

            throw e;
        }
    );
}

function stsHttp(msg) {
    msg.headers.m = game.stats.sessionId;
    msg.stubbed   = game.stubbed;

    return m.request({
        method     : "POST",
        url        : `/stsRequest?${msg.protocol}/${msg.command}`,
        headers    : {},
        data       : msg,
        background : true
    })
    .then((res) => res.body);
}

export default function stsRequest(msg, opts) {
    if (!msg.headers) {
        msg.headers = {};
    }

    if (!msg.type) {
        msg.type = "One";
    }

    // Ensure gamecode matches the game
    msg.protocol = msg.protocol.replace(".gw2.", `.${game.buildInfo.gameCode}.`);

    return (GW2.config.features.cliGate) ? stsNative(msg, opts) : stsHttp(msg, opts);
}

// Used for sts errors that aren't handled in a more friendly way
// sts.netError = function(err) {
//     if (!err || !isVal(err.code) || !isVal(err.product) || !isVal(!err.module) || !isVal(!err.line)) {
//         return;
//     }

//     _native.call("ShowNetErrorBasic", err.code, err.product, err.module, err.line);
// };
