import m from "mithril";

import { visible, create, destroy } from "../../util/character.js";

import css from "./previewer.css";

export default {
    view : function() {
        return m("div", {
                class : css.preview,
                // This isn't in a class because when navigating from home, the preview
                // panel would transition out from 0, even when set to 970px, fml
                style : {
                    left : visible() ? "470px" : "970px"
                }
            },
            m("div", { class : css.controls },
                m("div", {
                        class   : css.clear,
                        onclick : create
                    },
                    window.gemstore_strings.clearPreview
                ),

                m("div", {
                        class   : css.close,
                        onclick : () => {
                            const listEl = document.querySelector("[item-list]");

                            let scrollTop;

                            // Package view doesn't need to worry about scroll position
                            if (!listEl) {
                                destroy();

                                return;
                            }

                            const itemHeight = listEl.childNodes[0].clientHeight;

                            // Add partial item height to make sure we snap/scroll to the most visible item at the top
                            scrollTop = listEl.scrollTop + (itemHeight / 1.5);

                            // Find the top of the item
                            scrollTop = scrollTop - (scrollTop % itemHeight);

                            // Can't just divide by 2 for items on right, they need a bit of an adjustment
                            const rightAdjustment = (((scrollTop / itemHeight) % 2) === 1) ? (itemHeight / 2) : 0;

                            listEl.scrollTop = (scrollTop / 2) - Math.floor(scrollTop % 153) - rightAdjustment;

                            destroy();
                        }
                    }
                )
            )
        );
    }
};
