import m from "mithril";

import css from "./maintenance.css";

export default {
    view(vnode) {
        const { error = {}, orderId } = vnode.attrs || vnode; // mithril 0 and 1 compatible

        const i18n = window.gemstore_strings.buyGems.error;

        let _i18n = i18n;


        if (error.error === "steam") {
            _i18n = i18n.steam;
        } else if (error.error === "bep") {
            _i18n = i18n.bep;
        }

        return m("div", { class : css.container },
            m("div", { class : css.innerContent },
                m("h1", _i18n.header),
                m("p", _i18n.intro),

                orderId ? m("p", { class : css.orderId }, i18n.orderId, orderId) : null,
                error.errorCode ? m("p", { class : css.errorCode }, i18n.code, error.errorCode) : null
            )
        );
    }
};
