import { game } from "native";

import state from "../state.js";

import { visible, create, addItem, destroy } from "./character.js";

export default function showPreview(item, itemEl) {
    game.call("HideTextTooltip");

    // Don't preview clicked home items with no categories... or it'll show on home
    if (!item.previewable || (state.onHome() && item.categories.length < 1)) {
        return;
    }

    // already previewing, and not item isn't native preview
    if (visible() && !item.nativePreviewable) {
        addItem(item);

        return;
    }

    // scroll into view
    if (itemEl) {
        setTimeout(() => {
            itemEl.scrollIntoView({ behavior : "smooth" });

            // fixes CEF scaling issue
            document.documentElement.scrollTop = 0;
        }, 0);
    }

    // Start transitioning in the window before showing the character
    state.previewing       = !item.nativePreviewable;
    state.nativePreviewing = item.nativePreviewable;

    if (item.nativePreviewable) {
        destroy();
        addItem(item);

        return;
    }

    // Package items never animate the preview pane
    if (item.isPackage) {
        create();
        addItem(item);

        return;
    }

    window.setTimeout(() => {
        create();
        addItem(item);
    }, 200);
}
