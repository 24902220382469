import m from "mithril";

import { game } from "native";

/**
 * Get gem prices
 * @param {object} state xstate state
 * @returns {promise} array of gem amounts and prices
 */
export function getGemPrices({ context }) {
    return m.request({ url : `/ws/gems?${context.params}` });
}

/**
 * Send req to GS backend, which sends req to Steam to open overlay
 * @param {object} state xstate state
 * @returns {promise} orderId
 */
export function initPurchase({ context }) {
    return m.request({
        url    : `/ws/steamPurchase?${context.params}`,
        method : "POST",
        data   : context.gems[context.selectedIdx]
    });
}

/**
 * Set up callback from native for when user confirms/cancels in Steam overlay
 * @param {object} state xstate state
 * @returns {promise} resolves if successful, errors if not
 */
export function getSteamConfirmation({ context }) {
    return new Promise((res, rej) => {
        game.call("SteamPurchase", context.orderId).then(purchaseRes => {
                // we don't know if cancelled or denied, just authorized = false
                const { orderId, authorized } = purchaseRes;

                if (!authorized || context.orderId !== orderId) {
                    return rej("steamAuth");
                }

                res();
            },
            err => {
                rej(err);
            }
        );
    });
}

/**
 * Finalize transaction with Steam, and deliver items through BEP
 * @param {object} state xstate state
 * @returns {promise} order details
 */
export function finalize({ context }) {
    return m.request({
        url    : `/ws/steamFinalize?${context.params}`,
        method : "POST",
        data   : {
            orderId : context.orderId
        }
    });
}

/**
 * Delete orderId
 * @param {object} state xstate state
 * @returns {promise} orderId
 */
export function cancel({ context }) {
    return m.request({
        url    : `/ws/steamCancel?${context.params}`,
        method : "POST",
        data   : {
            orderId : context.orderId
        }
    });
}
