import m from "mithril";

import textTooltip from "text-tooltip";

import state      from "../../state.js";
import categories from "../../categories.js";

import breadcrumb from "./breadcrumb.js";
import category   from "./category-breadcrumb.js";
import box        from "./search-box.js";
import svg        from "../svg-icon/index.js";

import css from "./search-bar.css";

const i18n = window.gemstore_strings;

export default {
    view : function() {
        const home    = state.onHome();
        const history = state.onHistory();

        const showHome   = !home;
        const showCrumbs = !home && !history;

        const curCat  = state.search.category || [];
        const subCats = curCat.length ?
                categories[curCat[curCat.length - 1]].childCategories :
                [];

        let crumbs;

        // Building up this way instead of dynamically to ensure that it's a contiguous
        // array so keys work as expected to force recreation when things shift
        if (showCrumbs) {
            crumbs = curCat.slice(1);

            if (!home && subCats.length > 0 && !state.search.text) {
                crumbs.push("_all");
            }

            if (state.search.package) {
                crumbs.push("_package");
            }
        }

        return m("div", { class : home ? css.homebar : css.searchbar },

            m(box),

            showHome && m("button", {
                    class : css.home,

                    onclick     : () => m.route.set("/"),
                    onmouseover : textTooltip(i18n.tooltip.home),
                    onmouseout  : textTooltip(null)
                },

                m(svg, {
                    class : css.icon,
                    name  : "home"
                })
            ),

            showHome && m("button", {
                    class : css.back,

                    onclick     : () => state.pop(),
                    onmouseover : textTooltip(i18n.tooltip.back),
                    onmouseout  : textTooltip(null)
                },

                m(svg, {
                    class : css.icon,
                    name  : "back"
                })
            ),

            showCrumbs && m("div", {
                    class : css.breadcrumbs,

                    oncreate : (vnode) =>
                        window.requestAnimationFrame(() =>
                            (vnode.dom.className = css.visibleCrumbs)
                        )
                },

                crumbs.map((id, idx) => {
                    idx += 1;

                    // Package is a plain breadcrumb
                    if (id === "_package") {
                        const query = `src=breadcrumb&guid=${state.search.package}`;

                        return m(breadcrumb, {
                            key   : id,
                            name  : i18n.nav.packageBreadcrumb,
                            attrs : {
                                onclick : () => m.route.set(`/category/${state.search.category.join("/")}?${query}`)
                            }
                        });
                    }

                    // Otherwise everything is a category
                    return m(category, {
                        idx,
                        key : id + idx,

                        category : id
                    });
                })
            )
        );
    }
};
