import { game } from "native";

import upsellType from "../util/upsell-type";

const upgrades = {
    gw2 : {
        // upgrade_p4f, hot/pof combo
        upgrade_p4f : {
            gem_store_item_id : "EEFD627E-733A-4201-8129-D69B765CA4C2",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        },

        // upgrade_3, eod
        upgrade_3 : {
            gem_store_item_id : "2526B0A1-34D2-447A-90B6-2CDA2DB2C39F",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        },

        // upgrade_4
        upgrade_4 : {
            gem_store_item_id : "C526BD96-7283-4CDC-80EF-D5BAA5C4BA3E",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        },

        // upgrade_p4f_2, eodc
        upgrade_p4f_2 : {
            gem_store_item_id : "6C2E040C-6C8F-4695-B5FA-62C2FAA436F8",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        },
        // upgrade_p4f_3, also eodc
        upgrade_p4f_3 : {
            gem_store_item_id : "6C2E040C-6C8F-4695-B5FA-62C2FAA436F8",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        }
    },
    gw2cn : {
        // upgrade_p4f, hot/pof combo
        upgrade_p4f : {
            gem_store_item_id : "CC51E5FB-B1CF-4F2F-9492-0CC4AFC0F650",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        },

        // upgrade_3, eod
        upgrade_3 : {
            gem_store_item_id : "7AE428FA-1D55-4DD8-9F15-88F11094706F",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        },
        // upgrade_4
        upgrade_4 : {
            gem_store_item_id : "54647B72-4F34-4729-9C6B-5329D8F072D7",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        },

        // upgrade_p4f_2, eodc
        upgrade_p4f_2 : {
            gem_store_item_id : "A56C5549-9ED8-47D9-B4D1-A17D4D8A75E5",
            isUpgrade         : true,
            tags              : [],
            listings          : [{
                    quantity           : "1",
                    discount           : "0",
                    discount_pct       : "10000",
                    base_price         : "35",
                    discount_price     : "35",
                    vip_base_price     : "35",
                    vip_discount_price : "35"
                }],
            position : 36
        }
    }
};

/**
 * Add upgrade banners, to handle filtering due to no listings
 * @param {object} catalog
 * @returns {object} catalog data with applicable upgrade added
 */
export default function upgradeHack(catalog) {
    const gameCode = game.buildInfo.gameCode === "gw2cn" ? "gw2cn" : "gw2";
    const ut       = upsellType();
    const item     = upgrades[gameCode][ut];

    if (item && !catalog.items[item.gem_store_item_id]) {
        catalog.items[item.gem_store_item_id] = upgrades[gameCode][ut];
    }

    return catalog;
}
