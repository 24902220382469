import m from "mithril";

import { game } from "native";

import object from "object";

import stsRequest from "sts-request";

import state from "./state.js";

import getHistory from "./history/get-history.js";

import log from "./log.js";

import getCatalog  from "./catalog/get-catalog.js";
import loadCatalog from "./catalog/load.js";
import { byGuid }  from "./catalog/search.js";

export default function purchase(_purchases, extra) {
    const buildId   = game.buildInfo.id || 0;
    const purchases = Array.isArray(_purchases) ? _purchases : [ _purchases ];

    const item     = byGuid(purchases[0].guid);
    const args     = {
            Guid    : item.guid,
            BuildId : buildId,
            UseVip  : (game.stats.vip && game.stats.vip.active) ? 1 : 0,
            Time    : GemStore.gsOverrideTime
        };
    const isGift   = Boolean(purchases[0].recipient);
    const summary  = {
            totalDiscount : 0,
            totalPrice    : 0,
            numItems      : 0,
            gift          : isGift,
            message       : isGift && extra.message
        };
    const requests = [];

    if (args.UseVip) {
        args.VipLevel = game.stats.vip && game.stats.vip.level;
    }

    if (summary.message) {
        args.GiftMessage = summary.message;
    }

    if (item.isPackage) {
        (GW2.config.featureIds && GW2.config.featureIds[item.dataId] || []).forEach(function(featureId) {
            game.call("BuyFeature", parseInt(featureId, 10));
        });
    }

    purchases.forEach(function(purchase) {
        const recipientArgs = isGift ? { RecipientPortalId : purchase.recipient.id } : {};

        log({
            action        : isGift ? "giftPurchase" : "purchase",
            source        : isGift ? "gifting" : "purchasePrompt",
            purchaseGuid  : state.purchase.info.guid,
            purchaseCount : state.purchase.info.count,
            gemPrice      : state.purchase.info.price
        });

        requests.push(stsRequest({
            type     : "None",
            protocol : "Game.gw2.GemStore",
            command  : "Purchase",
            body     : object.merge(recipientArgs, args, {
                Quantity   : purchase.count,
                TotalPrice : purchase.price,
                Language   : game.stats.language || "",
                Items      : item.isPackage ?
                    item.included.map((pkgItem) => ({
                        Id       : pkgItem.guid,
                        Quantity : pkgItem.quantity
                    })) :
                    null,

                TestGroupGuid : state.group
            })
        }).then(function purchaseSuccess() {
            const unlock = game.stats.unlocks[item.dataId];

            if (!isGift && unlock && !unlock.permanent) {
                localStorage[`unlock:${item.dataId}`] = true;
            }

            purchase.success = true;

            getCatalog()
                .then(loadCatalog)
                .then(() => {
                    getHistory();
                    m.redraw();
                });

            summary.totalDiscount += purchase.discount;
            summary.totalPrice    += purchase.price;
            summary.numItems      += purchase.count;
        }, function purchaseFailure() {
            purchase.failure = true;

            return;
        }));
    });

    return Promise.all(requests).then(function() {
        const successes = purchases.filter(function(purchase) {
                return !purchase.failure;
            });

        if (successes.length <= 0) {
            throw new Error("purchase failure");
        }

        game.call("GetStats").then((stats) => {
            game.stats = stats;
        });

        return object.merge(summary, { purchases : purchases });
    });
}
