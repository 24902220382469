import { game } from "native";

import hover from "hover";

// Optional callback in case mouseover/mouseout need to do
// something in addition to showing/hiding a tooltip
export default function(text, cb) {
    return hover(function(e) {
        if (text) {
            game.call("ShowTextTooltip", text);
        } else {
            game.call("HideTextTooltip");
        }

        if (typeof cb !== "function") {
            e.redraw = false;

            return;
        }

        cb(e);
    });
}
