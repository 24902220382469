import { game } from "native";

const modules = {};

export default function(module) {
    if (!modules[module]) {
        modules[module] = game.import(module);
    }

    return modules[module];
}
