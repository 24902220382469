import state from "./state.js";

import { valid } from "./catalog/search.js";

import filterLifespans from "./util/filter-lifespans.js";

const categories   = window.gemstoreCategories;
const categoryKeys = Object.keys(categories);
const catNames     = {
        Featured_NAEU   : "featured",
        Featured        : "featured",
        Featured_China  : "featuredChina",
        "New Items"     : "new",
        "Ending Soon"   : "expiring",
        Discounts       : "sale",
        "Gold Exchange" : "exchange"
    };

let childrenUpdated = false;

function flatten(prev, curr) {
    prev.push(curr);

    if (categories[curr].childCategories.length) {
        return categories[curr].childCategories
            .map((cat) => cat.id || cat)
            .reduce(flatten, prev);
    }

    return prev;
}

// Clean up categories some
categoryKeys.forEach((id) => {
    const category = categories[id];

    // Just in case...
    category.id = id;

    // Reference the actual parent/child category objects instead of ids for easier traversal
    category.parentCategories = category.parentCategories.map((parentId) => categories[parentId]);
    category.childCategories  = category.childCategories.map((childId) => categories[childId]);

    category.unfilteredChildCategories = category.childCategories;
    category.unfilteredFeaturedItems   = category.featuredItems;

    if (category.name === "Root") {
        categories.root = category;
    }

    // Use named references for top level nav categories
    if (category.name.indexOf("BaseCategory_") === 0) {
        category.name             = category.name.replace("BaseCategory_", "").toLowerCase();
        categories[category.name] = category;
    }

    if (catNames[category.name] === "exchange") {
        category.isExchange = true;
    }

    // Additional categories that need aliases
    if (catNames[category.name]) {
        categories[catNames[category.name]] = category;
    }
});

// Run separate from above .forEach because lifespans need an id hanging off of all categories
categoryKeys.forEach(lifespans); // eslint-disable-line no-use-before-define

// Provide a flattened list of category children
function updateChildren() {
    categoryKeys.forEach((id) => {
        categories[id].children = categories[id].childCategories
            .map((cat) => cat.id)
            .reduce(flatten, [ id ]);
    });
}

updateChildren();

function findRoot(node, list) {
    let result;

    if (!node) {
        return false;
    }

    if (node.name.toLowerCase() === "root") {
        return list.slice(1);
    }

    if (node.name.toLowerCase() === "promotions") {
        return list;
    }

    for (const category of node.parentCategories) {
        result = findRoot(category, [ category.id ].concat(list));

        if (result) {
            break;
        }
    }

    return result;
}

// Marks parents up the chain as not empty when we know a child category has an item
function hasItems(node, items, childHasItems) {
    node.hasItems = childHasItems || items[node.id];

    if (node.hasItems) {
        // all parents can be marked as non-empty
        node.parentCategories.forEach((parent) => hasItems(parent, items, true));
    }
}

// Filter child categories & featured items based on the provided lifespans
function lifespans(cat) {
    cat = categories[cat];

    cat.featuredItems   = filterLifespans(cat.unfilteredFeaturedItems, cat.featuredItemLifespan);
    cat.childCategories = filterLifespans(cat.unfilteredChildCategories, cat.childLifespans);
}

export default categories;

// Given a category guid, return an array including parent categories(breadcrumbs)
export function parents(categoryGuid) {
    return findRoot(categories[categoryGuid], [ categoryGuid ]) || null;
}

export function update() {
    const items = {};

    // Walk catalog and mark non-empty categories
    Object.keys(state.catalog).forEach((guid) => {
        const item = state.catalog[guid];

        if (!valid(item)) {
            return;
        }

        item.categories.forEach((category) => (items[category] = true));
    });

    categoryKeys.forEach(lifespans);

    // Only need to do this once after getting non-cached catalog, probably belongs somewhere else...
    if (!childrenUpdated) {
        updateChildren();
        childrenUpdated = true;
    }

    // Now give every category an true/false empty property based on status
    categoryKeys.forEach((category) => hasItems(categories[category], items));
}
