import { game } from "native";

import object from "object";

import sort       from "./sort.js";
import { byGuid } from "./search.js";

// search index format details:
// https://confluence.corp.arena.net/display/ArenaNet/GemStore+2.0+Search+RFC
export default function(text) {
    const results = {};
    const gsIndex = window.gemstoreIndex.index;
    const gsGuids = window.gemstoreIndex.guids;

    let words;

    // node[0] = child nodes
    // node[1] = weight entries
    function findResults(node, wordLen, depth) {
        if (!node) {
            return;
        }

        // [ [weight, [guids]], [weight, [guids]], ... ]
        node[1].forEach((weightEntry) => {
            // Loop all items at each weight, and += their weight each weight entry
            weightEntry[1].forEach((itemId) => {
                const guid = gsGuids[itemId];

                // Diminishing returns for partial matches
                results[guid] = (results[guid] || 0) + weightEntry[0] * (wordLen / (depth + 1));
            });
        });

        object.each(node[0], (childNode) => findResults(childNode, wordLen, depth + 1));
    }

    if (game.stats.language === "zh") {
        // The index for chinese is basically 1 character deep
        // so split on all characters and relevant results will bubble
        text  = text.toLowerCase();
        words = text.split("");
    } else {
        text = text
            .toLowerCase()
            .replace(/\W\s/g, "");

        // splits words by word boundaries, excluding apostrophes
        words = (text.match(/(?!'.*')\b[\w']+\b/g) || []);
    }

    words.forEach((word) => {
        let node = [ gsIndex, []];

        if (word.length === 0) {
            return;
        }

        // Walk the tree to the starting point, which is the end of the typed in word
        for (let i = 0; i < word.length; i++) {
            if (!node) {
                continue;
            }

            node = node[0][word[i]];
        }

        findResults(node, word.length, word.length - 1);
    });

    return Object.keys(results)
        .sort((a, b) => {
            // Sort by weight
            if (results[a] !== results[b]) {
                return results[b] - results[a];
            }

            // Use generic sort algorithm order when same weight
            return sort(byGuid(a), byGuid(b));
        });
}
