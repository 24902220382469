import m from "mithril";

import { game } from "native";

import embeds from "embeds";

import state from "./state.js";
import log   from "./log.js";

import gemMaintenance from "maintenance/maintenance.js";

import itemCodes from "./components/item-codes/index.js";
import css       from "./components/item-codes/item-codes.css";
import gfn       from "./components/gfn/index.js";
import btnCss    from "button/button.css";

const i18n = window.gemstore_strings;

const redeemCode = {
    view(vnode) {
        const { frame } = vnode.attrs;

        return [
            m("div", { class : css.overlay },
                m("div", { class : css.overlayText },
                    i18n.codes.redeemitem.text
                ),
                m("button", {
                        class   : btnCss.btn,
                        onclick : () => state.showPopup(() => m(itemCodes))
                    },
                    i18n.codes.redeemitem.link
                )
            ),
            frame
        ];
    }
};

export default function(type) {
    log({ action : `open:${type}` });

    embeds({ type : type })
    .then(res => {
        if (type === "codes" && game.buildInfo.gameCode !== "gw2cn") {
            res.frame = m(redeemCode, { frame : res.frame });
        }

        if (type === "gems" && res.embedInfo.gfn) {
            res.frame = m(gfn, { embedInfo : res.embedInfo, showPopup : state.showPopup });
        }

        state.showPopup(res.frame);

        res.complete
            .then(() => {
                state.hidePopup();

                m.redraw();
            });
    })
    .catch(err => {
        if (type === "gems") {
            game.call("SetLoading", false);

            return state.showPopup(m(gemMaintenance));
        }

        console.log("unhandled error");
    });
}
