import template from "template";

import differenceInDays from "date-fns/difference_in_days";
import dateFormat       from "date-fns/format";

import { get as getTime } from "./time.js";
import { isSuppressed }   from "./mustHave.js";

import state from "../state.js";

const sub  = template.sub;
const i18n = window.gemstore_strings.items.item.callouts;

// Would prefer callouts in item component,
// but we need to sort based on them
function _calloutDetails(item) { // eslint-disable-line complexity
    const quantity   = item.quantity;
    const percentOff = quantity.percent_off;
    const isFree     = percentOff === "100" || percentOff === 100;
    const serverNow  = new Date(getTime());

    let type;

    if (item.tags.musthave && !isSuppressed(item.gem_store_item_id)) {
        // only want to change the calloutType (background)
        type = "mustHave";
    }

    if (item.missingFeatures) {
        return {
            type    : type || "locked",
            details : item.requirementMissing || window.gemstore_strings.items.item.features
        };
    }

    if (quantity.total_remaining) {
        const text = state.banner ? i18n.saleendingshort : i18n.saleending;

        return {
            type    : type || "endingLimit",
            text    : isFree ? i18n.freeEnding : text,
            details : sub(i18n[percentOff ? "quantityleftsale" : "quantityleft"], {
                quantity : quantity.total_remaining
            })
        };
    }

    if (item.expireTime) {
        const text = state.banner ? i18n.saleendingshort : i18n.saleending;

        return {
            type       : type || "endingTime",
            text       : isFree ? i18n.freeEnding : text,
            endTime    : item.expireTime,
            getDetails : function() {
                const msLeft = Date.parse(item.expireTime) - getTime();

                return sub(i18n[percentOff ? "timeleftsale" : "timeleft"], {
                    time : dateFormat(new Date(Date.parse("1-1-2016") + Math.max(msLeft, 0)), "HH:mm:ss")
                });
            }
        };
    }

    // Regular item sale
    if (percentOff) {
        const text = isFree ?
            i18n.free :
            sub(i18n.percentOff, { sale : percentOff });

        return {
            type    : type || "sale",
            details : i18n.limitedtime,
            text
        };
    }

    // Item is expiring from the gem store in 24h - x# of days
    if (item.expiring) {
        return {
            type : type || "expiring",
            text : sub(state.banner ? i18n.daysleft : i18n.daysavailable, {
                days : differenceInDays(item.end, serverNow) + 1
            })
        };
    }

    // Item is new - added to store < 14d ago
    if (item.new) {
        return {
            type : type || (item.returning ? "returning" : "new"),
            text : item.returning ? i18n.returning : i18n.new
        };
    }

    return {
        type : type || "noCallout"
    };
}

export default function(item) {
    const callout = _calloutDetails(item);

    if (item.quantity.account_remaining && callout.type !== "locked") {
        return {
            type     : callout.type || "noCallout",
            text     : callout.text,
            template : null,
            endTime  : null,
            details  : sub(i18n.accountleft, {
                amount : item.quantity.account_remaining
            })
        };
    }

    return callout;
}
