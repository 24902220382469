import m from "mithril";

import { game } from "native";

import css from "./popup.css";

function fadeIn(el) {
    el.classList.add(css.fadeIn);
}

export default function(content, className, hideCb) {
    if (!content) {
        return null;
    }

    return m("div", {
        class : css.overlay,

        // 1.x
        oncreate : (vnode) => fadeIn(vnode.dom),
        // 0.2.x
        config   : (el, init) => {
            if (!init) {
                fadeIn(el);
            }
        },

        onclick : function(e) {
            if (e.target === e.currentTarget) {
                hideCb();
            }
        }
    },
        m("div", {
                class : [
                    className || "",
                    game.buildInfo.gameCode === "gw2cn" ? css.large : css.popup
                ].join(" ")
            },
            m("div", { class : css.content }, content),
            m("button", {
                class   : css.close,
                onclick : hideCb
            })
        )
    );
}
