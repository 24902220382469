import isBefore from "date-fns/is_before";
import addDays  from "date-fns/add_days";

import { game } from "native";

import object from "object";

import state from "../state.js";

import parseQuantities    from "./parse-quantities.js";
import parseCallout       from "./parse-item-callout.js";
import { get as getTime } from "./time.js";

import keys            from "../util/keys.js";
import images          from "../util/images.js";
import filterLifespans from "../util/filter-lifespans.js";

import categories from "../categories.js";

function capitalize(str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

function upgradeAvailable(item) {
    const stats = game.stats;

    return (GW2.config.featureIds && GW2.config.featureIds[item.sku] || []).every((id) => {
        const feature = stats.features[id];

        return feature && feature.current < feature.maximum;
    });
}

function isBetween(date, start, end) {
    return isBefore(start, date) && isBefore(date, end);
}

function expireTime(endTime, serverNow) {
    if (endTime && isBetween(serverNow, addDays(endTime, -1), endTime)) {
        return endTime;
    }

    return;
}

// region restricted items (eg item.randomchance) ARE allowed if it's free
function filteredToFree(item) {
    if (!item.hasFree) { // this is set in parse-quantities.js
        return false;
    }

    item.quantities = item.quantities.filter(q => q.percent_off === "100"); // string due to .toFixed()

    return true;
}

// formatting catalog data for easier rendering in Mithril
export default function parseItem(src) {
    /* eslint complexity: 0, max-statements: 0 */
    const serverNow = new Date(getTime());
    const stats     = game.stats; // player info, gem balance
    const guid      = src.gem_store_item_id;
    const item      = object.merge({}, window.gemstoreCatalog[guid], src);
    const itemType  = capitalize(item.type || "");

    let deliverables, disablePreview;

    /* program:local */
    // local const and gemstoreSrv data out of sync, update WebConst and Const in P4 deploy
    if (!window.gemstoreCatalog[guid]) {
        console.error(`${guid} not in catalog, update WebConst and Const in P4 deploy`);
    }
    /* /program:local */

    // Simplify some lookups
    item[`is${itemType}`] = true;
    item.guid             = guid;
    item.dataId           = item.dataId ? parseInt(item.dataId, 10) : false;

    // categories have lifespan, just like items
    item.categories = filterLifespans(item.categories || [], item.categoryLifespans, { ignoreOrder : true });

    // test this!
    item.quantities = item.listings;

    // Turn tags into an object for easier lookup
    item.tags = item.tags.reduce((prev, curr) => {
        prev[curr.tag.toLowerCase()] = curr;

        return prev;
    }, {});

    item.images = images(item);

    // pw from gemstoreSrv to unlock strings (to prevent data mining) (maybe not used anymore)
    if (item.passwords) {
        keys.register(item.dataId, Object.keys(item.passwords).map((o) => ({
            id       : o,
            password : item.passwords[o]
        })));
        keys.inject(item.dataId);
    }

    // Stupid account limited system using data from native API
    if (object.get(stats.unlocks, [ item.dataId, "permanent" ]) || localStorage[`unlock:${item.dataId}`]) {
        item.quantities = [];
    }

    // Upgrade skus are actually features, so make sure that they haven't hit the max before
    // allowing them to be purchased
    if (item.isUpgrade && !upgradeAvailable(item)) {
        item.quantities = [];
    }

    // Feature Restrictions
    if (Array.isArray(item.requiredFeatures)) {
        item.missingFeatures = item.requiredFeatures.filter((id) => !stats.features[id].current);

        if (item.missingFeatures.length > 0 && item.missingFeatures.length === item.requiredFeatures.length) {
            // Items w/o custom "We can't sell you this" text need to be removed entirely
            if (!item.requirementMissing) {
                item.hide = true;
            } else {
                // Otherwise make the item not available for purchase
                item.disabled = true;
            }
        } else {
            item.missingFeatures = false;
        }
    }

    // Feature Restrictions
    if (Array.isArray(item.excludedFeatures)) {
        if (item.excludedFeatures.some((id) => stats.features[id].current > 0)) {
            item.hide = true;
        }
    }

    item.quantities = parseQuantities(item);
    item.soldOut    = item.quantities.length === 0;
    item.quantity   = item.quantities[0] || {};

    // Start / end times should be taken from active listings
    item.start = item.quantity.start || false;
    item.end   = item.quantity.end || false;

    // Blacklisted items by country
    if (item.randomchance && GW2.config.countryRngExcludes.indexOf(GW2.config.country) > -1 && !filteredToFree(item)) {
        item.requirementMissing = window.gemstore_strings.items.item.countryDisabled;
        item.disabled           = true;
        item.missingFeatures    = true;
        item.quantities         = [];
        item.giftable           = false;
    }

    if (item.isPackage) {
        // TODO : this can be removed if we're sure server will always send back package items w/ contents
        if (!item.contents) {
            /* program:!live */
            console.error("Package item found with no contents", item);
            /* /program:!live */
            item.contents = [];
        }

        deliverables   = item.quantity.deliverables || {};
        disablePreview = {};

        item.excluded = item.contents.filter((thing) => {
            // hijacking filter to track items that are in pkgs, why?
            // the items in a pkg might be sold out, but avail in a pkg
            // and we still want to preview them
            if (!item.soldOut && state.inPackage.indexOf(thing.guid) < 0) {
                state.inPackage.push(thing.guid);
            }

            // also hijacking to track disabled previews

            if (thing.disablePreview) {
                disablePreview[thing.guid] = true;
            }

            return !(thing.guid in deliverables);
        });

        item.included = Object.keys(deliverables).map((_guid) => ({
            guid           : _guid,
            quantity       : deliverables[_guid],
            disablePreview : disablePreview[_guid]
        }));

        if (item.included.length === 0) {
            item.soldOut = true;
        }
    }

    // Mostly callout related, but also used for client-side categories
    item.expireTime = expireTime(item.end, serverNow);
    item.expiring   = item.end && isBetween(serverNow, addDays(new Date(item.end), -7), new Date(item.end));
    item.new        = item.start && isBetween(serverNow, new Date(item.start), addDays(new Date(item.start), 14));
    item.returning  = item.new && item.returning;

    item.callout = parseCallout(item); // Sale ending, etc

    // Add the Discounts category to items on sale
    // Don't rely on the callout to avoid accidentally add extra items or missing some
    if (item.quantity.percent_off) {
        item.categories.push(categories.sale.id);
    }

    // Add on more categories if needed, don't be exclusive....
    [ "new", "expiring" ].forEach((type) => item[type] && item.categories.push(categories[type].id));

    // Mark the exchange item and add the exchange category to it
    if (GW2.config.banners[item.guid] === "Exchange") {
        item.isExchange = true;
        item.categories.push(categories.exchange.id);
    }

    return item;
}
