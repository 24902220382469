import getModule from "native-module";

import state      from "../state.js";
import { byGuid } from "../catalog/search.js";

const paperdoll = getModule("gw2/ui/paperdoll");

export function show() {
    paperdoll.then(module => module.show());
}

export function hide() {
    paperdoll.then(module => module.hide());
}

export function create() {
    state.previewing = true;

    paperdoll.then(module => {
        module.create(null, { x0 : 492, y0 : 22, x1 : 945, y1 : 533 });
    });
}

export function addItem(item) {
    let ids;

    if (item.isPackage) {
        ids = item.included.map(pkgItem => {
                const itemInfo = byGuid(pkgItem.guid);

                // Only attempt to add items that are previewable, otherwise they can hide items.
                return itemInfo.previewable &&
                        itemInfo.nativePreviewable === false &&
                        !pkgItem.disablePreview ?
                    itemInfo.dataId : false;
            })
            .filter(Boolean);
    } else {
        ids = [ item.dataId ];
    }

    paperdoll.then(module =>
        ids.forEach(id => module.previewItem(id))
    );
}

// TODO: Do I need to call clear, or does show still destroy/re-init?
export function destroy() {
    state.previewing = false;

    paperdoll.then(module =>
        // paperdoll.hide would technically work here since pd.show destroys as well
        module.destroy()
    );
}

export function visible() {
    return state.previewing;
}
