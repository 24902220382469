import m from "mithril";

import state                        from "../state.js";
import { update as categoryUpdate } from "../categories.js";

import { get }                    from "./storage.js";
import { update as searchUpdate } from "./search.js";
import parseItem                  from "./parse-item.js";

import upgradeHack from "./upgrade-hack";

export default function load(catalog) {
    if (!catalog) {
        return;
    }

    if (catalog.delta) {
        // On delta updates check to ensure that there is an actual catalog first
        // Can't combine w/ above conditional because it'll lead to state being BLOWN UP
        // every time an update comes in (& that's bad)
        if (!Object.keys(state.catalog).length) {
            state.catalog = get("gsCatalog:{lang}").items;
        }
    } else {
        state.catalog   = {};
        state.inPackage = [];

        if (GW2.config.features.upgradeHack) {
            catalog = upgradeHack(catalog);
        }
    }

    Object.keys(catalog.items).forEach((guid) => {
        state.catalog[guid] = parseItem(catalog.items[guid]);
    });

    // Update relevant data
    categoryUpdate();
    searchUpdate();

    if (state.showingCached) {
        m.redraw();
    }
}
