import m from "mithril";

import object from "object";
import hover  from "hover";

import svg from "../svg-icon/index.js";

import { show, hide, visible } from "../../util/character.js";

import css from "./breadcrumb.css";

function breadcrumbHover(vnode, e) {
    const el = e.currentTarget;

    // el.classList[e.type === "mouseover" ? "add" : "remove"](css.show);

    // Keep the character model in front when the leftmost dropdown is visible, it shouldn't overlap
    if (!visible() || Array.prototype.indexOf.call(el.parentNode.children, el) < 1) {
        return;
    }

    if (e.type === "mouseover") {
        hide();
    } else {
        show();
    }
}

export default {
    view : (vnode) => m("div",
        object.merge({
                class : css.breadcrumb,

                // For CSS targeting so the arrow can be removed on hover
                "data-removable" : !vnode.attrs.all,

                onmouseover : vnode.attrs.dropdown ? hover((e) => breadcrumbHover(vnode, e)) : null,
                onmouseout  : vnode.attrs.dropdown ? hover((e) => breadcrumbHover(vnode, e)) : null
            },
            vnode.attrs.attrs || {}
        ),

        m("p", { class : css.name }, vnode.attrs.name),
        vnode.attrs.dropdown && m(svg, {
            class : css.arrow,
            name  : "arrow"
        }),
        !vnode.attrs.all && vnode.attrs.dropdown && m(svg, {
            class : css.remove,
            name  : "remove"
        }),
        vnode.attrs.dropdown || null
    )
};
