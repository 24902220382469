import { game } from "native";

import template from "template";

export function key(name) {
    // If {lang} is in the name, sub in the current language
    // turns out we want some things to persist across languages
    return `${template.sub(name, { lang : game.stats.language })}:${game.player.portalUserId}`;
}

export function get(name) {
    const val = localStorage.getItem(key(name));

    // val === "undefined"
    // need val === "null" ?
    if (!val || val === "undefined") {
        return false;
    }

    return JSON.parse(val);
}

export function set(name, val) {
    localStorage.setItem(
        key(name),
        JSON.stringify(val)
    );
}
