import m  from "mithril";
import cx from "classnames";

import object from "object";
import hover  from "hover";

import state       from "../../state.js";
import { visible } from "../../util/character.js";
import preview     from "../../util/show-preview.js";
import itemTooltip from "../../util/item-tooltip.js";

import itemButtons from "../item-buttons/index.js";
import icon        from "../icon/index.js";
import links       from "../item-links/index.js";
import svg         from "../svg-icon/index.js";

import { suppress } from "../../catalog/mustHave";

import css from "./item.css";

export default {
    view : function(vnode) { // eslint-disable-line complexity
        const item      = vnode.attrs.item;
        const hoverable = item.isPackage || item.previewable;

        let iconCss = item.previewable ? css.previewable : css.iconContainer;

        if (item.isPackage) {
            iconCss = state.search.package ? css.packageOffIcon : css.packageIcon;
        }

        return m("li", {
                "data-guid"  : item.guid,
                "data-id"    : item.dataId,
                "data-hover" : hoverable ? "" : false,

                /* program:!live */
                // Make items w/o any categories show up really, REALLY crazy looking
                style : !item.categories.length && "background-color: #F00;",
                /* /program:!live */

                class : cx(
                    !visible() && state.search.package ? css.packageNoPreview : css.item,
                    item.callout.type && css[item.callout.type]
                ),

                onmouseover : () => {
                    if (item.tags.musthave) {
                        suppress(item);
                    }
                },

                onclick : () => {
                    // Before packages because we don't want to open preview when closing package view
                    if (item.previewable && !state.search.package && !item.isPackage) {
                        preview(item, vnode.dom);
                    }

                    if (state.search.package) {
                        return m.route.set(`/category/${state.search.category.join("/")}?guid=${item.guid}`);
                    }

                    if (item.isPackage) {
                        state.highlightItem(item, state.search.category);
                    }
                }
            },
            m("div", {
                    class : css.content
                },
                icon(item, {
                    containerClass : iconCss,
                    attrs          : {
                        onmouseover : hover(itemTooltip.bind(null, item)),
                        onmouseout  : hover(itemTooltip.bind(null, item))
                    }
                }),
                m("div", { class : css.bd },
                    item.callout ?
                        m("div", { class : css.callout },
                            object.get(item, "callout.text")
                        ) :
                        null,

                    m("h2", { class : css.name }, item.name),

                    itemButtons(item),

                    // TODO: put into a separate module if used anywhere other than item grid
                    (item.callout.details || item.callout.getDetails) ?
                        m("div", { class : css.detailsBox },
                            m("div", {
                                    class : cx(
                                        css.details,
                                        item.callout.type === "endingTime" && css.countdown
                                    )
                                },
                                m(svg, {
                                    class : css.alert,
                                    name  : "alert"
                                }),
                                item.callout.details || item.callout.getDetails && item.callout.getDetails()
                            )
                        ) :
                        null
                )
            ),
            // Giftable items don't give the whole cell a click state, so are
            // ignored for the [data-hover] attribute. Still show up in links though
            // so we to check for them down here
            hoverable || item.giftable ?
                m(links, {
                    item,
                    class    : css.links,
                    selector : `[data-guid="${item.guid}"]`
                }) :
                null
        );
    }
};
