import m from "mithril";

import state       from "../../state.js";
import restricted  from "restricted";
import textTooltip from "text-tooltip";
import svg         from "../svg-icon/index.js";

import gifting from "../gifting/index.js";
import log     from "../../log.js";

import css from "./links.css";

export default {
    view(vnode) {
        const args = vnode.attrs;
        const item = args.item;
        const i18n = window.gemstore_strings;

        return !args.package && item.giftable ?
            m("button", {
                    class   : css.link,
                    onclick : item.missingFeatures ?
                        restricted :
                        function(e) {
                            e.stopPropagation();

                            if (!state.features.gifting.enabled) {
                                return;
                            }

                            log({
                                action      : "open:gifting",
                                clickedGuid : item.guid
                            });

                            state.showPopup(() => m(gifting, { item : item }));
                        },

                    onmouseover : textTooltip(state.features.gifting.enabled ?
                        i18n.tooltip.gift :
                        state.features.gifting.tooltip
                    ),
                    onmouseout : textTooltip(null)
                },
                m(svg, {
                    name  : "gift",
                    class : css.icon
                })
            ) :
            null;
    }
};
