import m from "mithril";

import object     from "object";
import stsRequest from "sts-request";

import state  from "../state.js";
import images from "../util/images.js";

export default function getHistory() {
    return stsRequest({
        protocol : "Game.gw2.GemStore",
        command  : "GetHistory",
        body     : {
            Count : 30
        }
    })
    .then(function(history) {
        if (!history.purchases.length) {
            return [];
        }

        const purchases = history.purchases.map((purchase) => {
            purchase = object.merge(
                purchase,
                purchase.guid in state.catalog ?
                    state.catalog[purchase.guid] :
                    window.gemstoreCatalog[purchase.guid],
                {
                    purchased : {
                        count : parseInt(purchase.quantity, 10),
                        price : parseInt(purchase.total_price, 10)
                    },

                    images : images(window.gemstoreCatalog[purchase.guid]),
                    active : purchase.guid in state.catalog
                }
            );

            if (purchase.quantities) {
                purchase.quantities.some((qty) => {
                    if (qty.count === purchase.purchased.count && qty.price === purchase.purchased.price) {
                        return (purchase.purchased = qty);
                    }

                    return false;
                });
            }

            return purchase;
        })
        .filter((item) => !item.hide);

        state.purchases = purchases;

        if (state.flyout === "history") {
            m.redraw();
        }

        return purchases;
    });
}
