import m from "mithril";

import { game } from "native";

import accountStatus from "account-status";

import state        from "../state.js";
import categories   from "../categories";
import exchangeRate from "../exchange-rate.js";

import setupCatalog from "../catalog/setup.js";

import { destroy } from "../util/character.js";

import getHistory from "../history/get-history.js";
import history    from "./history/index.js";
import category   from "./category/index.js";
import home       from "./home/index.js";
import item       from "./item/index.js";
import search     from "./search/index.js";

import { search as catalogSearch } from "../catalog/search.js";
import { get }                     from "../catalog/storage.js";

import layout from "../components/layout/index.js";

import log from "../log.js";

import "gw2.css";

export default function() {
    const i18n           = window.gemstore_strings;
    const untrustedSteam = state.isSteamUser() && !game.stats.tScore;

    /* program:!live */
    window.state = state;

    // Timepicker: Override the time value before any sts requests
    GemStore.gsOverrideTime = get("gsStartTime");
    /* /program:!live */

    setupCatalog()
        .then(() => {
            const queryString = m.parseQueryString(document.location.search);

            // Get after a short wait, it's not so important
            // But do make sure to get it after the catalog has been populated!
            setTimeout(getHistory, 10);

            // logs for analytics
            setTimeout(log.bind(null, { action : "timer" }), GW2.config.logDelayMs);

            // Support legacy links to the gemstore from the game and navigate to the item specified
            if (queryString.id) {
                state.highlightItem(catalogSearch({ dataId : parseInt(queryString.id, 10) })[0]);

                return;
            }

            // Also support guid, because some are package items(Lava Lounge Pass) that have no dataId
            if (queryString.guid) {
                state.highlightItem(state.catalog[queryString.guid]);
            }

            // Coming from loading screen or quicklaunch click, go to specified item
            game.import("gw2/datastore").then((data) => {
                let _item;

                if (data.gsItem) {
                    _item = state.catalog[data.gsItem];

                    state.highlightItem(_item);

                    data.remove("gsItem");

                    m.redraw();
                }
            });
        });

    exchangeRate();
    window.setInterval(exchangeRate, 1000 * 60 * 3);

    const account = accountStatus();

    // todo: make better, I'm so sorry :(
    // some tooltips are rendered only if disabled, sometimes when enabled, sometimes by gamecode
    /* eslint no-nested-ternary: off */
    state.features = {
        gifting : {
            enabled : account.check("gifting") && !untrustedSteam,
            tooltip : !account.check("gifting") ?
                i18n.disabled.feature :
                untrustedSteam ?
                    i18n.disabled.tScore :
                    i18n.tooltip.gift
        },
        codes : {
            enabled : account.check("codes"),
            tooltip : account.check("codes") && game.buildInfo.gameCode === "gw2cn" ?
                i18n.tooltip.redeemcode :
                (!account.check("codes") && state.isSteamUser()) ?
                    i18n.tooltip.redeemSteam :
                    !account.check("codes") ?
                        i18n.disabled.feature :
                        null
        },
        purchasing : {
            enabled : account.check("purchasing"),
            tooltip : i18n.disabled.feature
        },
        billing : {
            enabled : account.check("billing"),
            tooltip : account.check("billing") ? null : i18n.disabled.feature
        }
    };

    // "Buy Gems" button in the Currency Exchange opens Gemstore to buy gems for Steam accounts
    // todo: this should probably just open in CurrEx, but this will suffice for now
    // (CurrEx doesn't have a popup, so there's some non-trivial work involved)
    state.openBuyGems = window.location.search.indexOf("openBuyGems") > -1;

    // TODO: Any reason this might be called excessively?
    game.on("stats", () => m.redraw());

    m.route(document.body, document.location.pathname, {
        "/" : {
            onmatch : () => {
                destroy();

                state.search = {};

                // Skip the first time
                if (m.route.get()) {
                    log({ action : "home" });
                }
            },

            render : () => m(layout, m(home))
        },

        "/history" : {
            onmatch : () => {
                destroy();

                state.search = {};

                log({ action : "history" });
            },

            render : () => m(layout, m(history))
        },

        "/category/:key..." : {
            onmatch : (args) => {
                const cats = args.key.toUpperCase().split("/");

                // Invalid category provided in url(maybe from game UI)
                if (!cats.every((cat) => categories[cat])) {
                    m.route.set("/");
                }

                state.search = {
                    category : cats
                };

                state.resultsChanged = true;

                if (args.text) {
                    state.search.text = window.decodeURIComponent(args.text);
                    // TODO : This is a bandaid - search box onblur is causing a redraw
                    //        and preventing clicks on items when the searchbox is focused
                    state.resultsChanged = false;
                }

                state.clickedItem = args.guid || (args.id && parseInt(args.id, 10));

                log({
                    source   : args.src,
                    position : args.position
                });
            },

            render : (vnode) => m(layout, m(category, vnode.attrs))
        },

        "/search" : {
            onmatch : (args) => {
                state.search = {};

                if (args.category) {
                    state.search.category = args.category.split("/");
                }

                if (args.text) {
                    state.search.text = window.decodeURIComponent(args.text);
                }

                log({
                    source   : args.src,
                    position : args.position
                });
            },

            render : () => m(layout, m(search))
        },

        "/item/:key..." : {
            onmatch : (args) => {
                state.search = {
                    package  : args.key,
                    category : window.decodeURIComponent(args.categories).split(",")
                };

                log({
                    source   : args.src,
                    position : args.position,
                    section  : args.section
                });
            },

            render : (vnode) => m(layout, m(item, vnode.attrs))
        }
    });
}
