import m  from "mithril";
import cx from "classnames";

import object from "object";
import hover  from "hover";

import textTooltip from "text-tooltip";

import state       from "../../state.js";
import icon        from "../icon/index.js";
import itemButtons from "../item-buttons/index.js";
import giftingLink from "../item-links/gifting-link.js";

import css from "./featured.css";

import log from "../../log.js";

const i18n = window.gemstore_strings;

export default {
    oninit : (vnode) => {
        vnode.state.activeIndex = {};
        vnode.state.prevBigItem = {};
    },
    view : (vnode) => m("div", { class : css.container },
        state.featuredResults.map((section, sectionIdx) => {
            const smallItems = section.items.concat([ null, null ]).slice(0, 3);
            let activeIndex  = vnode.state.activeIndex[section.category.id] || 0;
            let bigItem      = smallItems[activeIndex];

            // This happens if bigItem was just purchased and it was limited quantity. GW2BUGS-112162
            if (bigItem === null) {
                // Affected items roll off the smallItems, so we can just reset back to index 0.
                // Black Lion Keys and Level 80 boosts always seem to appear in these lists, so index 0 should be safe.
                vnode.state.activeIndex[section.category.id] = 0;
                activeIndex                                  = vnode.state.activeIndex[section.category.id];
                bigItem                                      = smallItems[activeIndex];
            }

            const itemsLoaded = Boolean(bigItem);

            return m("div",
                m("div", { class : css.categoryName },
                    m.trust(`&nbsp;${section.category.displayname}&nbsp;`)
                ),
                m("div", { class : css.items },
                    m("div", { class : css.bigPlaceholder },
                        m("div", {
                            class    : css.bigContainer,
                            key      : bigItem.guid,
                            // Start fading in after first render
                            oncreate : (vn) => {
                                requestAnimationFrame(() => vn.dom.classList.add(css.incoming));
                            },

                            // Fade out & remove once the transition completes
                            onbeforeremove : (vn) => new Promise((resolve) => {
                                vn.dom.addEventListener("transitionend", resolve);

                                vn.dom.classList.add(css.outgoing);
                            })
                        },
                            m("div", {
                                    class : cx(
                                        css.bigItem,
                                        css[object.get(bigItem, "callout.type")]
                                    ),
                                    "data-guid" : bigItem ? bigItem.guid : "",
                                    onclick     : () => {
                                        state.highlightItem(bigItem, section.category.id, {
                                            src      : "featured",
                                            position : activeIndex
                                        });
                                    },
                                    onmouseover : textTooltip(i18n.tooltip.details),
                                    onmouseout  : textTooltip("")
                                },
                                m("div", { class : css.callout },
                                    object.get(bigItem, "callout.text")
                                ),
                                itemsLoaded && [
                                    m("div", { class : css.scaleIcon },
                                        icon(bigItem, { expand : true })
                                    )
                                ]
                            ),
                            itemsLoaded && m("div", { class : css.prices },
                                m("div", { class : css.name },
                                    bigItem.name
                                ),
                                itemButtons(bigItem)
                            ),
                            (!bigItem.package && bigItem.giftable) ?
                                m("div", { class : css.giftingLink },
                                    m(giftingLink, { item : bigItem })
                                ) :
                                null
                        )
                    ),
                    m("div", { class : css.smallItems },
                        section.items.length > 1 && smallItems.map((item, idx) =>
                            m("div", {
                                    class : cx(
                                        css.smallItem,
                                        (!item || !item.guid) ? css.empty : "",
                                        css[object.get(item, "callout.type")],
                                        activeIndex === idx ? css.active : ""
                                    ),
                                    "data-guid" : item ? item.guid : "",
                                    onclick     : () => {
                                        state.highlightItem(item, section.category.id, {
                                            src      : "featured",
                                            position : activeIndex,
                                            section  : sectionIdx
                                        });
                                    },
                                    onmouseover : textTooltip(i18n.tooltip.details, () => {
                                        log({
                                            action      : "interaction",
                                            interaction : "hover",
                                            source      : "featured",
                                            position    : idx,
                                            section     : sectionIdx
                                        });
                                        vnode.state.activeIndex[section.category.id] = idx;
                                    }),
                                    onmouseout : hover(textTooltip(""))
                                },
                                itemsLoaded && m("div", { class : css.scaleIcon },
                                    icon(item, {
                                        class  : css.smallIcon,
                                        expand : true
                                    })
                                )
                            )
                        )
                    )
                )
            );
        })
    )
};
