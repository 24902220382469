import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import template from "template";

import textTooltip from "text-tooltip";
import hover       from "hover";

import itemTooltip from "../../util/item-tooltip.js";
import state       from "../../state.js";
import icon        from "../icon/index.js";

import { byGuid } from "../../catalog/search.js";

import css from "./suggestions.css";

function itemClick(itemGuid, o) {
    state.highlightItem(byGuid(itemGuid), state.flyout, o);

    // Don't clear the flyout until we've passed the category into highlightItem
    state.setFlyout(null);
}

export default {
    // Start fading in after first render
    oncreate : (vnode) => {
        requestAnimationFrame(() => vnode.dom && vnode.dom.classList.add(css.incoming));
    },

    // Fade out & remove once the transition completes
    onbeforeremove : (vnode) => new Promise((resolve) => {
        vnode.dom.addEventListener("transitionend", resolve);

        vnode.dom.classList.add(css.outgoing);
    }),

    view : () => {
        const results  = state.flyoutResults;
        const featured = results && results.featured;

        let blurb;

        if (!featured) {
            return null;
        }

        blurb = featured.blurb && featured.blurb.replace(/<[^>]*>/g, " ");

        if (featured.isExchange) {
            blurb = template.sub(blurb, {
                gold : state.exchange.gold,
                gems : state.exchange.gems
            });
        }

        return m("div", { class : css.suggestions },
            !featured.isExchange && m("div", { class : css.thumbnails },
                results.thumbnails.map((item, idx) =>
                    icon(item, {
                        class          : css.icon,
                        containerClass : `${css.iconContainer} ${css[item.callout.type]}`,
                        attrs          : {
                            onclick     : itemClick.bind(null, item.guid, { src : "flyout", position : idx }),
                            onmouseover : hover(itemTooltip.bind(null, item)),
                            onmouseout  : hover(itemTooltip.bind(null, item))
                        }
                    })
                )
            ),
            m("div", { class : css.featured },
                m("div", {
                        class       : css.bannerContainer,
                        onmouseover : textTooltip(window.gemstore_strings.tooltip.details),
                        onmouseout  : textTooltip(null),
                        onclick     : () => {
                            if (featured.isExchange) {
                                return game.call("ChangeTab", "Exchange");
                            }

                            return itemClick(featured.guid, { src : "flyout", position : 9 });
                        }
                    },
                    icon(featured, {
                        class     : css.banner,
                        imageSize : "splash"
                    }),
                    blurb ?
                        m("button", { class : css.learnMore },
                            window.gemstore_strings.banner.learnMore
                        ) :
                        m("div", { class : css.bannerText },
                            featured.name
                        )
                ),
                m("div", { class : cx(featured.callout.type && css[featured.callout.type]) },
                    featured.callout.text
                ),
                blurb
            )
        );
    }
};
