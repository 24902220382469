import { get } from "./storage.js";
import state   from "../state.js";

export default function cached() {
    const cached = get("gsCatalog:{lang}");

    if (!cached) {
        return Promise.reject("No cached data available");
    }

    state.showingCached = true;
    state.group         = cached.test_group_guid;

    return Promise.resolve(cached);
}
