import { game } from "native";

import { get } from "object";

/**
 * Check an account's features/expacs
 * @param {number} featNum eg 45 for hot
 * @returns {boolean}
 */
function checkAccountFeature(featNum) {
    return Boolean(game.stats.features?.[featNum]?.current);
}

/**
 * @typedef {Object} AccountStatus
 * @property {string} status
 * @property {Expansions} expansions
 * @property {Function} check
 * @property {Function} tooltip
 */

/**
 * @typedef {Object} Expansions
 * @property {boolean} hot
 * @property {boolean} hotUltimate
 * @property {boolean} expac2
 * @property {boolean} expac2Deluxe
 * @property {boolean} expac3
 * @property {boolean} expac3Deluxe
 * @property {boolean} expac4
 * @property {boolean} expac4Deluxe
 * @property {boolean} expac5
 * @property {boolean} expac5Deluxe
 */

/**
 * Get the account status
 * @param {}
 * @returns {AccountStatus} account status object
 */
export default function accountStatus() {
    const features    = GW2.config.features;
    const isSteamUser = game.stats.provider === "Steam";

    let status = "full";

    if (!get(game.stats.features, [ "2", "current" ])) {
        status = "free";
    }

    if (game.player.isEconomyRestricted) {
        status = "restricted";
    }

    return {
        status,
        // Lookup object defining what expansions the user may have
        expansions : {
            hot          : checkAccountFeature(45) || checkAccountFeature(64),
            hotUltimate  : checkAccountFeature(47),
            expac2       : checkAccountFeature(58),
            expac2Deluxe : checkAccountFeature(62),
            expac3       : checkAccountFeature(65),
            expac3Deluxe : checkAccountFeature(70),
            expac4       : checkAccountFeature(72),
            expac4Deluxe : checkAccountFeature(76),
            expac5       : checkAccountFeature(78),
            expac5Deluxe : checkAccountFeature(82)
        },

        // check which JsApp features are enabled (not game features)
        check(feature) {
            return (

                // Feature has to be enabled
                Boolean(features[feature]) &&

                // Full accounts always have access, otherwise check account-type subfield
                (status === "full" || Boolean(features[`${feature}-${status}`])) &&

                // Steam isn't an account status, but features can be enabled/disabled in the same way
                (!isSteamUser || Boolean(features[`${feature}-steam`]))
            );
        },

        tooltip(feature) {
            const i18n = window.Exchange.i18n.disabled;

            if (feature in features && !features[feature]) {
                return i18n.feature;
            }

            if (status === "restricted") {
                if (!game.stats.tScore) {
                    return i18n.untrusted;
                }

                return i18n.free;
            }

            // Other states just return the string
            return i18n[status];
        }
    };
}
