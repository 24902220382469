const clientLoadedTime = Date.now();

let serverLoadedTime = 0;

export function set(time) {
    serverLoadedTime = (new Date(time)).getTime();
}

export function get() {
    return serverLoadedTime + Date.now() - clientLoadedTime;
}
