import m  from "mithril";
import cx from "classnames";

import template from "template";

import state      from "../../state.js";
import categories from "../../categories.js";

import scroll      from "../../util/scroll.js";
import { visible } from "../../util/character.js";

import gridItem    from "../item/index.js";
import gridItemCss from "../item/item.css";
import packageItem from "../package-item/index.js";

import css from "./grid.css";

const i18n = window.gemstore_strings;

// TODO: I'd really like to replace the resultsChanged stuff with something
// simpler. It's a lot of book-keeping for something that seems like it could
// be handled at the component level. Not sure how to go about that yet, mostly
// because I don't want to recreate the grid component on every search change.
function highlight(vnode) {
    let el;

    if (!state.searchResults.length) {
        state.resultsChanged = false;

        return;
    }

    if (state.clickedItem) {
        // Item clicked animation takes priority
        if (state.resultsChanged) {
            state.resultsChanged = false;
        }

        el = document.querySelector(
            `[data-guid='${state.clickedItem}'],[data-id='${state.clickedItem}']`
        );

        if (!el) {
            return;
        }

        state.clickedItem = null;

        el.classList.remove(css.bling);

        requestAnimationFrame(() => {
            scroll(vnode.dom, el, 750, () => {
                el.classList.add(css.bling);

                m.redraw();
            });
        });
    }

    if (state.resultsChanged) {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                state.resultsChanged = false;

                m.redraw();
            });
        });
    }
}

export default {
    view : () => { // eslint-disable-line complexity
        const noResults   = !state.search.package && !state.searchResults.length;
        const lastCatName = state.search.category && categories[state.search.category.slice(-1)].displayname;

        let extraItems = [],
            items = state.searchResults;

        // Package is selected, return a different list
        if (state.search.package && state.searchResults[0]) {
            return m(packageItem);
        }

        if (noResults) {
            items = state.noResultsFiller;
        }

        if (!noResults && state.extraResults.length) {
            extraItems = [
                m("li", { class : css.extra },
                    state.extraRecsOnly ?
                        i18n.noResultsRecs :
                        template.sub(i18n.extraResults, { category : lastCatName })
                )
            ].concat(
                state.extraResults.map((item) => m(gridItem, {
                    key : item.guid,
                    item
                }))
            );
        }

        return m("div", { class : visible() ? css.containerSingle : css.container },
            noResults && state.search.text?.length ?
                [
                    m("h4", { class : css.noResults },
                        state.search.category ?
                            template.sub(i18n.noResultsCategory, { category : lastCatName }) :
                            i18n.noResults
                    ),
                    m("div", { class : css.recHeader }, i18n.noResultsRecs)
                ] :
                null,

            m("ul", {
                    class : cx(
                        visible() ? css.listSingleColumn : css.itemList,
                        state.resultsChanged && !state.clickedItem && gridItemCss.itemTransitionStart
                    ),

                    "item-list" : true,

                    oncreate : highlight,
                    onupdate : highlight
                },
                items.map((item) =>
                    m(gridItem, {
                        key : item.guid,
                        item
                    })
                )
                .concat(extraItems)
            )
        );
    }
};
