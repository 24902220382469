import m from "mithril";

import template from "template";

import state      from "../../state.js";
import categories from "../../categories.js";
import { update } from "../../catalog/search.js";
import svg        from "../svg-icon/index.js";

import css from "./search-box.css";

const i18n = window.gemstore_strings;

function suggestions() {
    const curCat = state.search.category || [];

    let suggestionIndex = curCat.length,
        suggestionCats  = curCat.length > 0 ?
            categories[curCat[curCat.length - 1]].childCategories :
            categories.root.childCategories;

    // TODO: revisit this
    if (curCat.length > 1 && suggestionCats.length <= 0) {
        suggestionCats  = categories[curCat[curCat.length - 2]].childCategories;
        suggestionIndex = curCat.length - 1;
    }

    if (state.search.text && curCat.length <= 0) {
        return null;
    }

    return m("ul", { class : css.suggestions },
        state.search.text && curCat.length > 0 && m("li", {
                class   : css.suggestion,
                onclick : () => m.route.set(`/search?text=${state.search.text}`)
            },

            template.sub(i18n.nav.searchAll, { search : state.search.text })
        ),
        !state.search.text && suggestionCats.map((cat) => {
            if (!cat.hasItems || cat.id === curCat[suggestionIndex] || cat.isExchange) {
                return null;
            }

            return m("li", {
                    class   : css.suggestion,
                    onclick : () => {
                        curCat[suggestionIndex] = cat.id;

                        m.route.set(`/category/${curCat.join("/")}`);
                    }
                },
                cat.displayname
            );
        })
    );
}

export default {
    view : () => {
        const home = state.onHome();

        return m("div", {
                class    : css.wrapper,
                disabled : state.onHistory()
            },
            m("input", {
                type        : "text",
                class       : css.input,
                placeholder : i18n.nav.search,
                value       : state.search.text || "",

                onkeydown : (e) => {
                    // date picker datepicker date-picker to help find this when searching code
                    /* program:!live */

                    // spacebar (in-game is returning 21 for space?)
                    const spaceKey = e.keyCode === 21 || e.keyCode === 32;

                    if (e.ctrlKey && e.shiftKey && spaceKey) {
                        state.timePicker = true;

                        return;
                    }
                    /* /program:!live */

                    e.redraw = false;

                    // "enter" submits on the home page
                    if (home && e.keyCode === 13) {
                        m.route.set(
                            `/search?${m.buildQueryString({ text : e.currentTarget.value })}`
                        );

                        return;
                    }
                },

                oninput : (e) => {
                    // TODO: delete if no .value
                    state.search.text = e.currentTarget.value;

                    if (state.search.package) {
                        const query = m.buildQueryString({ text : state.search.text });

                        m.route.set(
                            `/category/${state.search.category.join("/")}?${query}`
                        );
                    }

                    if (!home) {
                        update();
                    }
                },

                // TODO: need to remove this and approach the problem a different way
                onblur : () => {
                    let current, query;

                    if (!state.search.text || home) {
                        return;
                    }

                    // TODO: Probably useful elsewhere
                    current = m.route.get();
                    query   = current.indexOf("?") > -1;

                    if (query) {
                        current = current.split("?");
                        query   = m.parseQueryString(current[1]);
                        current = current[0];
                    } else {
                        query = {};
                    }

                    query.text = state.search.text;

                    m.route.set(`${current}?${m.buildQueryString(query)}`);

                    // TODO: Disabled for now because it makes elements unclickable
                    // due to sync redraw after events, not sure how to handle...
                    // vnode.state.suggestions = false;
                }
            }),

            m(svg, {
                class   : css.search,
                name    : "search",
                onclick : () => {
                    if (!state.search.text || !home) {
                        return;
                    }

                    m.route.set(`/search?${m.buildQueryString({ text : state.search.text })}`);
                }
            }),

            suggestions()
        );
    }
};
