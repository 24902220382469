import m from "mithril";

export default {
    view : (vnode) => m("svg", vnode.attrs,
        m("use", {
            oncreate : (vn) => {
                // Chrome sometimes fails to load SVGs and it's supposedly a cache/timing issue.
                // Delaying loading this seems to fix it - doing this in oncreate is usually enough,
                // but not always - hence the raf
                window.requestAnimationFrame(
                    () => vn.dom.setAttributeNS(
                        "http://www.w3.org/1999/xlink",
                        "href",
                        `/gen/icons.svg#icon-${vnode.attrs.name}`
                    )
                );
            }
        })
    )
};
