import { get as getTime } from "../catalog/time.js";
import isBefore           from "date-fns/is_before";

// Filter child categories & featured items based on the provided lifespans
export default (unfiltered, lifespanList, o) => {
    const guidCount = {};

    let serverNow = getTime();

    serverNow = serverNow ? new Date(serverNow) : new Date();

    return unfiltered.filter((item) => {
        const guid = item.id || item;

        let lifespans = lifespanList && lifespanList[guid];

        if (!lifespans) {
            return true;
        }

        if (!Array.isArray(lifespans)) {
            lifespans = [ lifespans ];
        }

        // Just check if any of the lifespans are valid and assume there are no duplicate item entries
        if (o && o.ignoreOrder) {
            return lifespans.some((_lifespan) =>
                _lifespan && isBefore(_lifespan.start, serverNow) && isBefore(serverNow, _lifespan.end));
        }

// Find the lifespan that corresponds to this item->timespans mapping in order to preserve sort ordering from content
// https://www.flowdock.com/app/arenanet/gemstore-2-0/threads/C44KTs5IaQmvnNCIwOYzym4_tT0
// note: There can be multiple timespan entries per-item in content, but we only get a list of
//       timespans keyed by item guid in categories.js, so we assume the timespan list preserves duo/content ordering
        guidCount[guid] = guidCount[guid] ? (guidCount[guid] + 1) : 1;

        const lifespan = lifespans[guidCount[guid] - 1];

        return lifespan && isBefore(lifespan.start, serverNow) && isBefore(serverNow, lifespan.end);
    });
};
