import { game } from "native";

import stsRequest from "../sts-request";

const embedApi = {
    complete : function(message, embed) {
        embed._gw2EmbedComplete();
    },

    launchbrowser : function(message) {
        game.call("ShowInDefaultBrowser", message.data.url || "");
    },

    getsession : function(message) {
        stsRequest({
            protocol : "Auth",
            command  : "GetUserInfo"
        }).then(data => {
            message.source.postMessage({
                command : "getsession",
                data    : {
                    id     : game.stats.sessionId,
                    userId : data.user_id,
                    email  : data.login_name
                }
            }, message.origin);
        });
    },

    features : function(message) {
        message.source.postMessage({
            command : "features",
            data    : game.stats.features
        }, message.origin);
    },

    getorigin : function(message, embed) {
        embed.embedSource = message.source;
        embed.embedOrigin = message.origin;

        message.source.postMessage({
            command : "getorigin",
            data    : {
                origin : window.location.origin
            }
        }, message.origin);
    },

    imeclient : function(message) {
        const data   = message.data;
        const state  = data && data.state;
        const width  = data && data.width;
        const height = data && data.height;

        state.left = state.left + ((950 - width) / 2);
        state.top  = state.top + ((700 - height) / 2);

        GW2.Ime = state;
    }
};

/**
 * Check if a string ends with another string
 * @param {string} str
 * @param {string} end
 * @returns {boolean}
 */
function strEndsWith(str, end) {
    return str.indexOf(end, str.length - end.length) !== -1;
}

/**
 * Check if the host is in the list of approved embed origins
 * @param {string} origin
 * @returns {boolean}
 */
function checkHost(origin) {
    const url  = new URL(origin);
    const host = url.hostname; // don't want the port

    return GW2.config.embedOrigins.some(function(domain) {
        return strEndsWith(host, domain);
    });
}

/**
 * Update the IME state
 * @param {object} e
 * @param {object} embed
 * @returns {boolean}
 */
function updateIme(e, embed) {
    const source = e.source;
    const data   = e.data;

    if (source === window && data && data.length && data[0] === "ime:native") {
        embed.embedSource.postMessage(data, "*");

        return true;
    }
}

/**
 * Handle messages from the embed
 * @param {object} embed
 * @param {object} e
 */
function handleMessage(embed, e) {
    const commandName = (typeof e.data.command === "string") && e.data.command.toLowerCase();
    const command     = embedApi[commandName];

    if (updateIme(e, embed) || (typeof command !== "function") || !checkHost(e.origin)) {
        return;
    }

    command(e, embed);
}

export default {
    handleMessage : handleMessage
};
