import m from "mithril";

import stsRequest from "sts-request";

import state from "./state.js";

export default function() {
    return stsRequest({
        protocol : "Game.gw2.Exchange",
        command  : "GetInverseQuote",
        body     : {
            Type     : "ReceivingCoins",
            Quantity : GW2.config.exchangeGold * 10000
        }
    })
    .then(function(resp) {
        if (state.exchange.gems !== resp.quantity) {
            m.redraw();
        }

        state.exchange.gems = resp.quantity;
        state.exchange.gold = GW2.config.exchangeGold;
    });
}
