import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import hover from "hover";
import gems  from "elements/gems-mithril/gems";

import btnCss from "button/button.css";

import state from "../../state.js";
import mSub  from "../../util/sub.js";

import icon from "../icon/index.js";

import css from "./gifting.css";

const i18n = window.gemstore_strings.gifting;

export default function(item) {
    const success = state.gifting.success;

    return m("div", { class : css.gifting },
            m("div", { class : css.successHd },
                m("h2", { class : css.title },
                    i18n.step.success.title
                ),
                m("div",
                    i18n.step.success.intro
                )
            ),
            m("div", { class : css.successBd },
                m("div", { class : css.successDetails },
                    m("div", i18n.step.success.summary.title),
                    m("div", { class : css.successItem },
                        icon(item, { class : css.icon }),
                        m("div",
                            item.name
                        )
                    ),
                    state.gifting.success.message ?
                        m("div",
                            i18n.step.success.summary.messagelabel,
                            m("div", { class : css.successMessage },
                                state.gifting.success.message
                            )
                        ) :
                        null,
                    m("div",
                        i18n.step.success.summary.totallabel,
                        m("div", { class : css.successTotal },
                            gems(success.totalPrice)
                        )
                    )
                ),
                m("div",
                    m("div",
                        i18n.step.success.summary.recipientslabel
                    ),
                    m("div", { class : css.successRecipients },
                        m("ul", { class : css.recipientsList },
                            success.purchases.map((purchase) => m("li", {
                                    class       : purchase.failure ? css.failedRecipient : css.recipient,
                                    onmouseover : purchase.failure && hover(() =>
                                        game.call("ShowTextTooltip", i18n.error.purchase.contact)
                                    ),
                                    onmouseout : purchase.failure && hover(() =>
                                        game.call("HideTextTooltip")
                                    )
                                },
                                m("div", { class : css.successName },
                                    purchase.recipient.name
                                ),
                                purchase.success && m("div", { class : css.successPrice },
                                    mSub(i18n.price, {
                                        amount : purchase.count,
                                        price  : gems(purchase.price)
                                    })
                                )
                            )
                        ))
                    )
                )
            ),
            m("button", {
                    class   : cx(btnCss.btn, css.successButton),
                    onclick : state.hidePopup
                },
                window.gemstore_strings.flow.button.ok
            )
        );
}
