import m from "mithril";

import css from "./prompt.css";

// heading/body/footer options should be arrays of m() elements
export default function(content, className, hideFn) {
    if (!content) {
        return null;
    }

    return m("div", {
            onclick : function(e) {
                if (e.currentTarget === e.target && typeof hideFn === "function") {
                    hideFn();
                }
            },
            class : css.overlay
        },
        m("div", {
                class : `${css.prompt} ${className}` || ""
            },
            m("div", { class : css.inner },
                content
            )
        )
    );
}
