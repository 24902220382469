import m from "mithril";

import state       from "../../state.js";
import historyItem from "../../components/history-item/index.js";

import css from "./history.css";

const i18n = window.gemstore_strings;

export default {
    view : () => [
        m("div", { class : css.headings },
            [ "name", "date", "price" ].map((col) =>
                m("div", { class : css[col] },
                    i18n.history[col]
                )
            )
        ),
        m("ul", { class : css.items },
            state.purchases.length > 0 ?
                state.purchases.map(historyItem) :
                m("h4", { class : css.noHistory },
                    i18n.nav.noHistory
                )
        )
    ]
};
