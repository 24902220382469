import isBefore from "date-fns/is_before";

import { game } from "native";

import object from "object";

import state      from "../state.js";
import categories from "../categories.js";
import images     from "../util/images.js";

import searchText         from "./search-text.js";
import sort               from "./sort.js";
import { get as getTime } from "./time.js";

// Check if the given category filter is in any of the item's categories or child categories
function categoryMatch(filter, item) {
    if (!filter || !item.categories.length || !categories[filter]) {
        return false;
    }

    // Check if any of the item's categories are part of the filter or its children
    return item.categories.some((cat) => categories[filter].children.indexOf(cat) > -1);
}

export function valid(item, hidden) {
    if (!item || item.soldOut || (item.hide && !hidden)) {
        return false;
    }

    return true;
}

export function search(_criteria, skipSort) {
    // Copy criteria to ensure we don't override state.search.xxxx
    const criteria = object.merge(_criteria);
    const fields   = Object.keys(criteria);
    const sorted   = Boolean("text" in criteria && criteria.text.length);
    const category = Array.isArray(criteria.category) && criteria.category.length && criteria.category.slice(-1)[0];

    let results;

    if (criteria.package) {
        return [ state.catalog[criteria.package] ];
    }

    // Search & filter by text first if there's a search term, then by categories/filters
    // then filter out items that may not be in the catalog yet due to timing
    if (criteria.text) {
        results = searchText(criteria.text);

        // Don't want to re-filter on this
        delete criteria.text;
    } else {
        results = Object.keys(state.catalog);
    }


    results = results.filter((guid) => {
        const item = state.catalog[guid];

        if (!valid(item)) {
            return false;
        }

        return fields.every((key) => {
            const value = criteria[key];

            // Carousel uses tags
            if (key === "tag") {
                return item.tags[value];
            }

            if (key === "category") {
                if (!item.categories.length) {
                    /* program:!live */
                    // Allow items w/o a category to show up everywhere on non-live branches
                    return true;
                    /* /program:!live */
                    /* program:live
                    return false;
                    /program:live */
                }

                return category ? categoryMatch([ category ], item) : true;
            }

            return item[key] === value;
        });
    })
    .map((guid) => state.catalog[guid]);

    // Don't want to re-sort if already sorted
    if (sorted || skipSort) {
        return results;
    }

    // All others get sorted according to the algorithm
    results.sort(sort);

    if (category) {
        results.sort(sort.bind(results, category));
    }

    return results;
}

function sortWeight(item) {
    let weight = item.tags.splash.start ? -Date.parse(item.tags.splash.start) : 10000000000000;

    if (item.callout.type.indexOf("ending") === 0) {
        weight -= 30000000000000;
    }

    if (item.callout.type === "sale") {
        weight -= 20000000000000;
    }

    if (item.disabled) {
        weight = Infinity;
    }

    return weight;
}

// Find unfiltered recommendations, based on position alone
function recommendations() {
    return search({}, true)
        .slice(0, 20)
        .sort((a, b) => a.position - b.position);
}

function exclude(list, excludes) {
    return list.filter((item) =>
        excludes.every((excl) => item.guid !== excl.guid)
    );
}

 /* eslint max-statements: 0 */
export function update() {
    const home        = state.onHome();
    const featuredCat = ([ "gw2cn", "gw2-mdv" ].indexOf(game.buildInfo.gameCode) !== -1 &&
            categories.featuredChina) ? "featuredChina" : "featured";
    const featured    = categories[featuredCat] ?
            categories[featuredCat].childCategories.slice(0, 5) :
            [];

    let used;

    if (home || state.banner) {
        const fakeBannerItems = [];
        const serverNow       = new Date(getTime());

        object.each(window.gemstoreCatalog, (item, guid) => {
            if (item.type !== "banner") {
                return;
            }

            if ((item.availableDate && isBefore(serverNow, item.availableDate)) ||
                (item.removeDate && isBefore(item.removeDate, serverNow))
            ) {
                return;
            }

            item.guid = guid;
            item.tags = {
                splash : { start : item.availableDate }
            };

            item.images  = images(item);
            item.callout = { type : "none" };

            fakeBannerItems.push(item);
        });

        state.carouselResults = search({ tag : "splash" })
            .concat(fakeBannerItems)
            .sort((a, b) => sortWeight(a) - sortWeight(b));
    }

    if (home) {
        used = {};

        featured.push({ id : "recommended", displayname : window.gemstore_strings.recommendedCat });

        state.featuredResults = featured.map((cat) => {
            const items = [];

            search(cat.id === "recommended" ? {} : { category : [ cat.id ] }).some((result) => {
                // Don't show results that are already showing in other featured sections
                if (result.guid in used) {
                    return false;
                }

                items.push(result);
                used[result.guid] = true;

                // Only need 3, so stop as early as possible
                return items.length === 3;
            });

            return {
                category : cat,
                items    : items
            };
        })
        .filter((results) => results.items.length > 0)
        .slice(0, 3);

        return;
    }

    state.searchResults = search(state.search);

    state.extraResults = [];

    if (state.searchResults.length < 7) {
        // Text searches within a category that had < 7 results
        // might have results outside that category, so strip off
        // the category and search the entire store & combine with
        // filler items to create a new, hopefully more useful list
        if (state.search.text && state.search.category) {
            // Start with items that show up with this search term, but are outside the current category
            state.extraResults = exclude(
                search(object.merge(state.search, { category : [] })),
                state.searchResults
            );

            // Flag so we know if the extra results are all recommendations
            state.extraRecsOnly = state.extraResults.length <= 0;
            // Append recommendations while excluding search results & existing extras
            state.extraResults = state.extraResults.concat(
                exclude(recommendations(), state.extraResults.concat(state.searchResults))
            )
            .slice(0, 10);
        }
    }

    if (state.searchResults.length === 0) {
        state.noResultsFiller = state.extraResults.length ? state.extraResults : recommendations();

        state.resultsChanged = false;
    }
}

// Look up via state.catalog (active items) & fallback to gemstoreCatalog (all items)
export function byGuid(guid) {
    return state.catalog[guid] || window.gemstoreCatalog[guid];
}
