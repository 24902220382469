import m from "mithril";

import { game } from "native";

import restricted from "restricted";
import gems       from "elements/gems-mithril/gems";


import openBuyGems from "../buy-gems/openBuyGems";

import state    from "../../state.js";
import { hide } from "../../util/character.js";
import log      from "../../log.js";

import css from "./item-buttons.css";

export function button(item, quantity, src) {
    return m(".action",
        m("button", {
                class : quantity.count > 1 ?
                    css.qtyButton :
                    css[quantity.percent_off ? "buttonSale" : "button"],

                onclick : item.missingFeatures || !state.features.purchasing.enabled ?
                    restricted :
                    function(e) {
                        e.stopPropagation();

                        // can this code path be hit?
                        if (!state.features.purchasing.enabled) {
                            return;
                        }

                        if (quantity.price > game.stats.gems) {
                            openBuyGems();

                            return;
                        }

                        hide();

                        game.call("HideTextTooltip");

                        state.purchase.info = {
                            guid  : item.guid,
                            price : quantity.price,
                            count : quantity.count
                        };

                        log({
                            action        : "priceClick",
                            source        : src === "history" ? "history" : "item",
                            purchaseGuid  : state.purchase.info.guid,
                            purchaseCount : state.purchase.info.count,
                            gemPrice      : state.purchase.info.price
                        });

                        if (state.flyout) {
                            state.setFlyout(null);
                        }
                    }
            },
            m("div", { class : css.priceContainer },
                quantity.count > 1 ?
                    m("span", { class : css.qty }, quantity.count) :
                    null,

                gems(quantity.price, { class : css.price })
            )
        ),
        quantity.regular_price && src !== "history" && m("div", { class : css.regularPrice },
            quantity.count > 1 && `${quantity.count}/ `,
            quantity.regular_price
        )
    );
}

export default function(item, src) {
    return m("div", { class : css.actions },
        item.quantities.map((qty) => button(item, qty, src))
    );
}
