import m from "mithril";

import { update } from "../../catalog/search.js";

import previewer from "../../components/previewer/index.js";
import pkg       from "../../components/package-item/index.js";

import css from "../gemstore.css";

export default {
    oninit : update,

    view : () => m("div", { class : css.body },
        m(pkg),
        m(previewer)
    )
};
