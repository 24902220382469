import {
    getGemPrices,
    initPurchase,
    getSteamConfirmation,
    finalize,
    cancel
} from "./gemsRequests";

// map state name to invoked action
const invokeActions = {
    gettingPrices    : getGemPrices,
    startingPurchase : initPurchase,
    waitingForSteam  : getSteamConfirmation,
    finalizing       : finalize,
    cancelling       : cancel
};

export default function invoke(state, service) {
    const action = invokeActions[state.value];

    if (!action) {
        return;
    }

    action(state)
        .then(data => {
            service.send({ type : "ONDONE", data });
        })
        .catch(error => {
            service.send({ type : "ONERROR", error });
        });
}
