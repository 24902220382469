import m         from "mithril";
import dateValid from "date-fns/is_valid";
import addDays   from "date-fns/add_days";

import { key, get, set }       from "../../catalog/storage.js";
import { show, hide, visible } from "../../util/character.js";

import css from "./time-picker.css";

function isoTime(datetime) {
    const date = new Date(datetime);

    if (!datetime || !dateValid(date)) {
        return false;
    }

    return date.toISOString().replace(/\.\d{3}/, "");
}

function commit(time) {
    set("gsStartTime", isoTime(time));

    window.location.reload();
}

function revert() {
    localStorage.removeItem(key("gsStartTime"));

    window.location.reload();
}

function changeDay(date, amt) {
    date = date ? new Date(date) : new Date();

    commit(addDays(date, amt));
}

export default {
    oninit() {
        this.savedTime = isoTime(get("gsStartTime"));
    },

    view(vnode) {
        const valid = vnode.state.timePicker && dateValid(new Date(vnode.state.timePicker.associated.value));

        return m("div", { class : css.timePicker + (!valid ? ` ${css.invalid}` : "") },
            m("div", GW2.config.country),
            m("button.cancel", {
                class   : css.button,
                onclick : revert
            }, m.trust("&#x2718;")),

            // one day less
            m("button", {
                    class   : css.button,
                    onclick : changeDay.bind(null, vnode.state.savedTime, -1)
                },
                "-"
            ),

            m("input", {
                oncreate : (vn) => {
                    vnode.state.timePicker = window.rome(vn.dom, {
                        initialValue : get("gsStartTime") || new Date(),
                        inputFormat  : "YYYY-MM-DD HH:mm:ss ZZ"
                    });

                    vnode.state.timePicker.on("show", () => visible() && hide());
                    vnode.state.timePicker.on("hide", () => visible() && show());
                },
                class     : css.input,
                onkeydown : valid && ((e) => {
                    if (e.keyIdentifier === "Enter" || e.keyCode === 105) {
                        commit(vnode.state.timePicker.getDateString());
                    }
                })
            }),

            // one day more
            m("button", {
                    class   : css.button,
                    onclick : changeDay.bind(null, vnode.state.savedTime, 1)
                },
                "+"
            ),

            m("button.save", {
                class   : css.button,
                onclick : valid && (() => commit(vnode.state.timePicker.getDateString()))
            }, m.trust("&#x2714;"))
        );
    }
};
