import m from "mithril";

import { game } from "native";

import embedApi from "./embed-api";

const build = m.buildQueryString || m.route.buildQueryString;

// New goodness
export default function(config) {
    game.call("SetLoading", true);

    return m.request({
        url : `/ws/embed/v2/${config.type}?${build({
            s              : game.stats.sessionId,
            lang           : game.stats.language.slice(0, 2),
            visible        : config.visible,
            china          : config.china,
            upgrades       : config.upgrades, // todo: not used by china? remove after ex5pp
            owned          : config.owned, // naeu buysite only
            required       : config.required, // naeu buysite only
            provider       : config.provider, // naeu buysite only
            remoteClientIp : game.player.remoteClientIp
        })}`
    })
    .then(embedInfo => {
        // upgrade, upgrade_2, upgrade_p4f use baseUrlType to differentiate baseUrl
        // but share config.type to avoid extra billing type configs

        let resolver;

        // Store resolve fn externally so it can be available via the DOM
        const complete = new Promise(resolve => {
            resolver = resolve;
        });

        const frame = m("iframe[seamless]", {
            src   : embedInfo.url,
            style : {
                background : "transparent",
                width      : `${embedInfo.width}px`,
                height     : `${embedInfo.height}px`
            },

            onload() {
                game.call("SetLoading", false);
            },

            // Old mithril
            config(el, isInit) {
                if (!isInit) {
                    el._gw2EmbedComplete = resolver;

                    window.onmessage = embedApi.handleMessage.bind(null, el);
                }
            },

            // New mithril
            oncreate(vnode) {
                vnode.dom._gw2EmbedComplete = resolver;

                window.onmessage = embedApi.handleMessage.bind(null, vnode.dom);
            }
        });

        return {
            complete,
            frame,
            embedInfo
        };
    });
}
