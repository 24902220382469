import { game } from "native";

import stsRequest from "sts-request";

import { set, get } from "./storage.js";
import state        from "../state.js";

import { getPersistedSuppressed } from "./mustHave";

function saveCatalog(data) {
    const cachedCatalog = data.delta ? get("gsCatalog:{lang}") : false;
    const itemMap       = cachedCatalog.items || {};

    // Save off items as an object keyed by guids, not an array to
    // avoid doing this work every time deltas get merged
    data.items.forEach((item, idx) => {
        if (data.delta) {
            // New delta items get put at the end, replaced delta items use the existing position
            item.position = itemMap[item.gem_store_item_id] ? itemMap[item.gem_store_item_id].position : 1000;
        } else {
            item.position = idx;
        }

        itemMap[item.gem_store_item_id] = item;
    });

    data.items = itemMap;

    set("gsCatalog:{lang}", data);
}

getPersistedSuppressed();

export default function getCatalog() {
    return stsRequest({
        protocol : "Game.gw2.GemStore",
        command  : "GetCatalog",

        body : {
            Time       : GemStore.gsOverrideTime,
            BuildId    : game.buildInfo.id || 0,
            // No lastUpdate when the Deltas are disabled (to disable catalog deltas, send all instead)
            LastUpdate : GW2.config.features.catalogDeltas && (get("lastUpdate:{lang}") || undefined)
        }
    })
    .then(data => {
            data.delta = "is_delta" in data;

            // Keep track of this value separately rather than doing work setting the whole catalog string every time
            set("lastUpdate:{lang}", data.last_update);

            // Add/update the cached catalog in localStorage
            if (!data.delta || (data.delta && data.items.length > 0)) {
                saveCatalog(data);
            }


            if (!data.delta) {
                // Store recommendation group for passing back w/ purchases
                state.group = data.test_group_guid;
            }

            // Real data now, so no longer showing cached state
            state.showingCached = false;

            return data;
        },
        err => {
            if (
                !err ||
                !(err instanceof Object) ||
                !("code" in err) ||
                !("product" in err) ||
                !("module" in err) ||
                !("line" in err)
            ) {
                return;
            }

            game.call("ShowNetErrorBasic", err.code, err.product, err.module, err.line);
        }
    );
}
