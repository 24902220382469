import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import template from "template";
import object   from "object";

import textTooltip from "text-tooltip";
import hover       from "hover";

import state      from "../../state.js";
import icon       from "../icon/index.js";
import upsellType from "../../util/upsell-type.js";

import css from "./carousel.css";
import log from "../../log.js";

const i18n          = window.gemstore_strings;
const banners       = GW2.config.banners || {};
const sub           = template.sub;
const upsellBanners = GW2.config.upsellBanners || {};

let lastPageChange = 0,
    upsellGUID, rotation, dragStart;

function carouselItems() {
    let items = state.carouselResults;

    if (items.length > 0 && upsellGUID) {
        items = [ state.catalog[upsellGUID] ].concat(state.carouselResults).filter(Boolean);
    }

    return items;
}

function viewItem(attrs) {
    const items     = carouselItems();
    const index     = attrs.index;
    const prevIndex = (attrs.index + items.length - 1) % items.length;
    const nextIndex = (attrs.index + 1) % items.length;
    const thisItem  = items[index];
    const prevItem  = items[prevIndex];
    const nextItem  = items[nextIndex];
    const active    = attrs.type === "active";
    const hasClick  = active && (thisItem.type !== "banner" || thisItem.gemStoreBannerItem);

    prevItem.carouselIndex = prevIndex;
    thisItem.carouselIndex = index;
    nextItem.carouselIndex = nextIndex;

    return m("li", {
                class : cx(
                    css.item,
                    css[attrs.type]
                ),
                onmouseover : hasClick && textTooltip(i18n.tooltip.details),
                onmouseout  : hasClick && textTooltip(null),

                // Noop in some cases - https://jira.corp.arena.net/browse/GW2BUGS-46479
                onclick(e) {
                    if (!hasClick) {
                        return;
                    }

                    const bannerValue = banners[thisItem.guid];

                    // special handling for banners in config
                    if (bannerValue && document.querySelector("[data-splash]").contains(e.target)) {
                        if (bannerValue === "Exchange") {
                            return game.call("ChangeTab", "Exchange");
                        }

                        game.call("ShowInDefaultBrowser", sub(bannerValue, {
                            lang : game.stats.language
                        }));
                    }

                    // Don't highlight to the item if the hot tag exists, just go to the first category
                    if (thisItem.tags.hot) {
                        state.goToCategory(thisItem);

                        return null;
                    }

                    if (thisItem.guid === upsellGUID) {
                        // localStorage so upgrade type is available in upgrade SPA
                        localStorage.upgradeType = upsellType();
                        game.import("gw2/ui/dialog").then((dialog) => {
                            dialog.toggleNative("UpSell");
                        });

                        return null;
                    }

                    state.highlightItem(thisItem, null, { src : "carousel", position : index });

                    return null;
                }
            },
            // When an element moves backwards in the dom list, even with a key, mithril replaces it.
            // This causes the backwards animation to not work if we only have left/mid/next items in the list...
            // so we need to render all items, oh well
            items.map((item) => {
                let blurb = item.blurb;

                if (item.isExchange) {
                    blurb = sub(item.blurb, {
                        gold : state.exchange.gold,
                        gems : state.exchange.gems
                    });
                }

                return m("div", {
                        class : cx(
                            css.banner,
                            item.carouselIndex === prevIndex && css.left,
                            item.carouselIndex === index && css.middle,
                            item.carouselIndex === nextIndex && css.right
                        ),
                        "data-guid" : item.guid,
                        key         : item.guid
                    },

                    icon(item, {
                        imageSize : "splash",
                        class     : css.icon
                    }),

                    m("div", { class : css.text },
                        item.callout && m("div", { class : css[item.callout.type] },
                            object.get(item, "callout.text")
                        ),
                        m("h2", { class : css.name },
                            blurb ? blurb.split("<br>").map(line => m("div", line)) : item.name
                        )
                    )
                );
            })
        );
}

function changePage(pageNumber, { interaction, position } = {}) {
    // throttle
    if ((Date.now() - 300) < lastPageChange) {
        return;
    }

    if (interaction) {
        log({
            action : "interaction",
            source : "carousel",
            interaction,
            position
        });
    }

    state.carousel = {
        index     : pageNumber,
        prevIndex : state.carousel.index
    };

    // TODO : needed?
    lastPageChange = Date.now();
}

// clear auto advance slides
function clear() {
    if (!rotation) {
        return;
    }

    clearTimeout(rotation);

    rotation = null;
}

// auto advance slides
function schedule() {
    clear();

    rotation = setTimeout(() => {
        const carousel = state.carousel;

        changePage((carousel.index + 1) % carouselItems().length, false);

        m.redraw();

        schedule();
    // show upsell banners for 2x as long
    }, upsellGUID && state.carousel.index === 0 ? 10000 : 5000);
}

export default {
    oninit() {
        upsellGUID = upsellBanners[upsellType()];
        schedule();
    },

    // Ensure that scheduled rotations are cancelled when
    // this controller is unmounted
    onremove : clear,

    view(vnode) {
        const carousel = state.carousel;
        const items    = carouselItems();

        const prevPage = (carousel.index + items.length - 1) % items.length;
        const nextPage = (carousel.index + 1) % items.length;

        if (!items.length) {
            return null;
        }

        return m("div", {
                class         : css.carousel,
                "data-splash" : true,

                onmousewheel(e) {
                    const prev = e.wheelDelta > 0;

                    changePage(prev ? prevPage : nextPage, { interaction : prev ? "scrollPrev" : "scrollNext" });
                },
                onmousedown(e) {
                    dragStart = e.x;
                },
                onmouseup(e) {
                    if (e.x > (dragStart + 40)) {
                        changePage(prevPage, { interaction : "dragNext" });
                    }

                    if (e.x < (dragStart - 40)) {
                        changePage(nextPage, { interaction : "dragPrev" });
                    }
                },
                // Track hover status to enable/disable autorotation
                onmouseover : hover((e) => {
                    clear();

                    e.redraw = false;
                }),
                onmouseout : hover((e) => {
                    schedule();

                    e.redraw = false;
                })
            },

            // next prev
            m("div", {
                class       : css.prevPage,
                onclick     : changePage.bind(null, prevPage, { interaction : "clickPrev" }),
                onmouseover : textTooltip(i18n.tooltip.previous),
                onmouseout  : textTooltip(null)
            }),
            m("div", {
                class       : css.nextPage,
                onclick     : changePage.bind(null, nextPage, { interaction : "clickNext" }),
                onmouseover : textTooltip(i18n.tooltip.next),
                onmouseout  : textTooltip(null)
            }),

            // carousel items
            m("ul", {
                    class : `${css.items}  ${vnode.state.skipAnimation ? css.skipAnimation : ""}`
                },
                [
                    { index : prevPage, type : "prev" },
                    { index : carousel.index, type : "active" },
                    { index : nextPage, type : "next" }
                ].map(viewItem)
            ),

            // carousel pips
            m("div", { class : css.pager },
                items.map((item, idx) => m("div", {
                    class : cx(
                        css.indicator,
                        idx === carousel.index && css.activePage
                    ),
                    onclick() {
                        changePage(idx, { interaction : "clickPager", position : idx });

                        if (idx === carousel.index || idx === nextPage || idx === prevPage) {
                            return;
                        }

                        // Don't animate if not next/prev item or things get weird
                        // 2 RAF to make sure the class shows up
                        vnode.state.skipAnimation = true;
                        window.requestAnimationFrame(
                            () => window.requestAnimationFrame(() => {
                                vnode.state.skipAnimation = false;
                            })
                        );
                    }
                }))
            )
        );
    }
};
