import m from "mithril";

import state      from "../../state.js";
import categories from "../../categories.js";

import { show } from "../../util/character.js";

import breadcrumb from "./breadcrumb.js";

import css from "./breadcrumb.css";

const i18n = window.gemstore_strings;

export default {
    view : (vnode) => {
        const id  = vnode.attrs.category;
        const idx = vnode.attrs.idx;

        const all    = id === "_all";
        const search = state.search.category;
        const parent = idx === 0 ? categories.root : categories[search[idx - 1]];

        return m(breadcrumb, {
            idx,
            all,
            name     : all ? i18n.nav.category.all : categories[id].displayname,
            dropdown : m("ul", { class : css.dropdown },
                parent.childCategories.map((sibling) => {
                    // Filter out some options
                    if (sibling.id === search[idx] || !sibling.hasItems || sibling.isExchange) {
                        return null;
                    }

                    return m("li", {
                            class   : css.ddItem,
                            onclick : (e) => {
                                e.stopPropagation();

                                search[idx] = sibling.id;

                                show();

                                m.route.set(`/category/${search.slice(0, idx + 1).join("/")}?src=breadcrumb`);
                            }
                        },
                        sibling.displayname
                    );
                })
            ),
            attrs : {
                onclick : () => {
                    show();

                    m.route.set(`/category/${search.slice(0, idx).join("/")}?src=breadcrumb`);
                }
            }
        });
    }
};
