const webImagesBase = GW2.imageBase;

export default function(item) {
    const imageBase = `${webImagesBase}/${item.imageHash || "44e4c6dd"}`;

    return {
        large  : `${imageBase}_large.png`,
        splash : `${imageBase}_splash.jpg`
    };
}
