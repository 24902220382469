import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import template from "template";
import hover    from "hover";

import state       from "../../state.js";
import categories  from "../../categories.js";
import historyItem from "../history-item/index.js";
import events      from "../nav/mouse-events.js";

import suggestions from "./suggestions.js";

import css from "./flyout.css";

const i18n = window.gemstore_strings.nav;

function openExchange() {
    game.call("ChangeTab", "Exchange");
}

function sectionLink(parentGuid, subCat) {
    const list = [ parentGuid ];

    if (subCat && !subCat.hasItems) {
        return null;
    }

    if (subCat) {
        list.push(subCat.id);
    }

    const hovered = subCat && state.flyout && state.flyout[1] === subCat.id;

    return m("li", {
            class   : cx(css.category, hovered && css.hovered),
            onclick : (subCat && subCat.isExchange) ? openExchange : (e) => {
                state.setFlyout(null);
                events.sectionClick(list, e);
            },
            onmouseover : hover(events.sectionHover.bind(null, list))
        },
        subCat ?
            subCat.displayname :
            template.sub(i18n.allCategory, { category : categories[parentGuid].displayname })
    );
}

function historyView() {
    return m("div", { class : css.flyout },
        m("div", { class : css.history },
            state.purchases.length > 0 ?
                m("ul",
                    state.purchases.map(historyItem)
                ) :
                m("div", { class : css.noHistory },
                    i18n.noHistory
                )
        ),
        m("div", { class : css.categoryInfo },
            i18n.recent
        )
    );
}

export default {
    view : () => {
        const cat = state.flyout && categories[state.flyout[0]];

        if (state.flyout === "history") {
            return historyView();
        }

        if (!state.flyout || state.flyout.indexOf(cat.id) === -1) {
            return null;
        }

        return m("div", { class : css.flyout },
            m("ul", {
                class           : css.categories,
                "data-longlist" : (categories[cat.id].childCategories.length >= 9).toString()
            },
                sectionLink(cat.id),
                categories[cat.id].childCategories.map((sub) => sectionLink(cat.id, sub))
            ),
            // http://mithril.js.org/keys.html#avoid-mixing-keyed-and-non-keyed-vnodes-in-the-same-array
            // ¯\_(ツ)_/¯
            [
                m(suggestions, { key : state.flyout.join("-") })
            ],
            m("div", { class : css.categoryInfo },
                categories[cat.id].description
            )
        );
    }
};
