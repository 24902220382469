import categories from "../categories.js";

const END = 65535;

// ISO8601 is sortable, yay
function dateOrderString(date, reverse) {
    if (date && reverse) {
        date = new Date(9999999999999 - date.getTime());
    }

    return (date || new Date(9999999999999)).toISOString();
}

// Sorting within sorting is complicated, especially when numbers are involved, so this is insane - sorry
// Notes: All sort strings need to have the same number of characters to sort correctly,
// so we write to orderString when not applicable
//        String.fromCharCode creates characters from 0->65535 that will reliably sort within that range
function comparator(item, cat) { // eslint-disable-line complexity
    const salePercent   = parseInt(item.quantity && item.quantity.percent_off, 10);
    const featuredIndex = categories[cat] ? categories[cat].featuredItems.indexOf(item.guid) : -1;

    let orderString = "";

    orderString = String.fromCharCode(item.disabled ? END : 1);

    // Override items first. If there are multiple override items they will obey the same rules
    // as below within themselves.
    orderString += String.fromCharCode(featuredIndex === -1 ? END : 1);

    // Sales ending within 24 hours -> in order of soonest to expire to furthest off
    orderString += dateOrderString(salePercent && item.expireTime);

    // Items leaving the store with 24 hours -> soonest to expire to furthest
    orderString += dateOrderString(!salePercent && item.expireTime);

    // Sale Items with discounts of 40% or greater.
    orderString += String.fromCharCode((salePercent && salePercent >= 40) ? 1 : END);

    // New items -> Most recent to least recent
    orderString += dateOrderString(item.new && item.start, true);

    // Returning items -> Most recent to least recent
    orderString += dateOrderString(item.returning && item.start, true);

    // Sale items with discounts < 40%.
    orderString += String.fromCharCode((salePercent && salePercent < 40) ? 1 : END);

    // Items expiring in more than 24 hours -> soonest to expire to furthest
    orderString += dateOrderString(item.expiring && !item.expireTime && item.end);

    // Featured order if featured, otherwise use recommendation position
    // TODO : use item.position here once there's a default recommendation set & don't alpha sort
    orderString += String.fromCharCode(featuredIndex !== -1 ? featuredIndex : END /* item.position(rec engine) */);


    // alpha sort the rest
    orderString += item.name;

    return orderString;
}

export default function sortFn(a, b, c) {
    let cat;

    // If a category is passed in as the first argument, shift things around & plumb through
    if (typeof a === "string") {
        cat = a;
        a   = b;
        b   = c;
    }

    return comparator(b, cat) < comparator(a, cat) ? 1 : -1;
}
