import m from "mithril";

import { game } from "native";

import css from "./gems.css";

export default function(gems, o) {
    const total    = parseInt(gems, 10);
    const extraCss = o && o.class;
    const digits   = (`${total}`).length;

    return m(
        "span", {
            class         : `${css.gems} ${extraCss ? extraCss : ""}`,
            total         : total,
            "data-digits" : digits
        },
        digits > 3 ? total.toLocaleString(game.stats.language) : total
    );
}
