import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import object from "object";

import stsRequest from "sts-request";
import hover      from "hover";
import btnCss     from "button/button.css";

import state from "../../state.js";

import css from "./item-codes.css";

const i18n = window.gemstore_strings.codes;

function codeCleaner(code) {
    return code.replace(/[^A-Z0-9]/gi, "");
}

export default {
    oninit() {
        const self = this;

        this.successItems = [];
        this.gameAccount  = null;

        this.itemHover = function(dataId, e) {
            if (e.type === "mouseover") {
                game.call("ShowItemTooltip", dataId);
            } else {
                game.call("HideItemTooltip");
            }
        };

        this.redeem = function() {
            const code = codeCleaner(self.code);

            return Promise.all([
                stsRequest({
                    protocol : "Auth",
                    command  : "ListMyGameAccounts",
                    body     : {
                        GameCode : game.buildInfo.gameCode
                    }
                })
                // Since we're specifying GameCode, the first one should match
                .then((o) => o[0]),

                stsRequest({
                    protocol : "Game.gw2.GemStore",
                    command  : "GetKeyInfo",
                    body     : {
                        ProductKey : code
                    }
                })
                .then((o) => ({
                    all   : o.items.concat(o.features || []),
                    items : o.items
                }))
            ])
            .then((results) => {
                const acct  = results[0];
                const items = results[1].all;

                if (!items.length) {
                    throw new Error("No items from code");
                }

                return stsRequest({
                    protocol : "Auth",
                    command  : "AddProductKey",
                    body     : {
                        GameCode   : game.buildInfo.gameCode,
                        ProductKey : code,
                        Alias      : acct.alias,
                        Language   : game.stats.language || "en"
                    }
                })
                .then(() => items);
            })
            .then((items) =>
                game.call("QueryItemInfo", {
                    items : items.map((item) => parseInt(item.data_id, 10))
                })
                .then((resp) =>
                    items.map((item) => object.merge(item, resp[item.data_id]))
                )
            )
            .then((items) => (self.successItems = items))
            .catch((err) => {
                self.error = i18n.step.code.error;

                if (err.reason === "ErrCdKeyInUse") {
                    self.error = i18n.step.code.already.used;
                }
            });
        };

        this.code = "";

        this.error = null;
    },

    view(vnode) {
        const validCode = codeCleaner(vnode.state.code).length === 25;

        if (vnode.state.successItems.length > 0) {
            return m("div", { class : css.container },
                m("div", { class : css.hd },
                    m("h4", { class : css.title }, i18n.step.success.title),
                    i18n.step.success.item.text
                ),
                m("div", { class : css.successBd },
                    m("h4", { class : css.successTitle }, i18n.step.success.header),
                    m("ul", { class : css.items },
                        vnode.state.successItems.map((item) =>
                            m("li", { class : css.item },
                                m("div", {
                                        class        : css.icon,
                                        "data-count" : item.count > 1 ? item.count : "",
                                        onmouseover  : hover(vnode.state.itemHover.bind(null, item.dataId)),
                                        onmouseout   : hover(vnode.state.itemHover.bind(null, null))
                                    },
                                    m("img", { src : `coui://item/${item.data_id}` })
                                ),

                                m("div", { class : css.details },
                                    m("div", { class : cx(css.name, item.rarityWord.toLowerCase()) }, item.name)
                                )
                            ))
                    )
                ),
                m("div", { class : css.successFt },
                    m("button", {
                            class   : btnCss.btn,
                            onclick : function() {
                                vnode.state.successItems = [];
                            }
                        },
                        i18n.button.anothercode
                    ),
                    m("button", {
                            class   : btnCss.btn,
                            onclick : state.hidePopup
                        },
                        window.gemstore_strings.flow.button.done
                    )
                )
            );
        }

        return m("div", { class : css.container },
            m("div", { class : css.hd },
                m("h4", { class : css.title }, i18n.redeemitem.link),
                i18n.step.code.intro
            ),
            m("div", { class : css.bd },
                m("input", {
                    class       : cx(css.key, !validCode && css.invalid),
                    placeholder : i18n.placeholder.code,
                    maxlength   : 29,
                    oninput     : (e) => {
                        vnode.state.code = e.currentTarget.value;
                    }
                }),
                m("button", {
                        class   : btnCss.large,
                        onclick : vnode.state.redeem
                    },
                    i18n.button.redeem
                )
            ),
            vnode.state.error ?
                m("div", { class : css.ft }, m.trust(vnode.state.error)) :
                null
        );
    }
};
