import m from "mithril";

import { createMachine, assign } from "@xstate/fsm";

import { game } from "native";

const build = m.buildQueryString || m.route.buildQueryString;

export default function createBuyGemsMachine() {
    return createMachine({
        id      : "buyGemsMachine",
        initial : "gettingPrices",

        context : {
            gems : [
                // {
                //     amount : 800,
                //     price : 1000,
                //     prettyPrice : "$10.00",
                //     id : 64
                // }
            ],
            // params : object,
            // loading : boolean,
            // selectedIdx : number
            // orderId : string,
            // transId : string,
            // error : object,
            // cancelled : boolean
        },

        states : {
            gettingPrices : {
                entry : assign({
                    params : () => build({
                        s        : game.stats.sessionId,
                        lang     : game.stats.language.slice(0, 2),
                        gameCode : game.buildInfo.gameCode,
                        type     : "gems"
                    })
                }),
                on : {
                    ONDONE : {
                        target  : "idle",
                        actions : assign({
                            gems : (ctx, { data }) => data
                        })
                    },
                    ONERROR : {
                        target  : "error",
                        actions : assign({
                            error : (ctx, { error }) => error
                        })
                    }
                }
            },

            idle : {
                on : {
                    NEXT : {
                        target  : "startingPurchase",
                        actions : assign({
                            selectedIdx : (ctx, { idx }) => idx,
                            loading     : () => true,
                            cancelled   : () => false
                        })
                    }
                }
            },

            startingPurchase : {
                on : {
                    ONDONE : {
                        target  : "waitingForSteam",
                        actions : assign({
                            orderId : (ctx, { data }) => data.orderId
                        })
                    },
                    ONERROR : {
                        target  : "cancelling",
                        actions : assign({
                            error : (ctx, { error }) => error
                        })
                    }
                }
            },

            waitingForSteam : {
                on : {
                    ONDONE  : "finalizing",
                    ONERROR : {
                        target  : "cancelling",
                        actions : assign({
                            // error : (ctx, { error }) => error
                            cancelled : () => true
                        })
                    }
                }
            },

            finalizing : {
                on : {
                    ONDONE : {
                        target  : "success",
                        actions : (ctx, { data }) => {
                            ctx.steamId     = data.steamId;
                            ctx.transId     = data.transId;
                            ctx.amount      = data.amount;
                            ctx.totalPretty = data.totalPretty;
                            ctx.items       = data.items;
                            ctx.loading     = false;

                            return ctx;
                        }
                    },
                    ONERROR : {
                        target  : "cancelling",
                        actions : assign({
                            error : (ctx, { error }) => error
                        })
                    }
                }
            },

            success : {
                // final, but fsm doesn't support final
            },

            cancelling : {
                on : {
                    ONDONE : [
                        {
                            cond    : (ctx) => ctx.cancelled,
                            target  : "idle",
                            actions : (ctx) => {
                                delete ctx.transId;
                                delete ctx.amount;
                                delete ctx.totalPretty;
                                delete ctx.items;
                                ctx.loading = false;

                                return ctx;
                            }
                        },
                        {
                            target  : "error",
                            actions : assign({
                                loading : () => false
                            })
                        }
                    ],
                    // duplicated so invoke can be DRYer
                    ONERROR : {
                        target  : "error",
                        actions : assign({
                            loading : () => false
                        })
                    }
                }
            },

            error : {
                // final, but fsm doesn't support final
            }
        }
    });
}
