function easeBoth(t, b, c, d) {
    t /= d / 2;

    if (t < 1) {
        return c / 2 * t * t + b;
    }

    t--;

    return -c / 2 * (t * (t - 2) - 1) + b;
}

export default function(container, toNode, duration, callback) {
    const from   = container.scrollTop;
    const height = container.clientHeight;
    const to     = Math.min(toNode.offsetTop - container.offsetTop, container.scrollHeight - height);
    const change = to - from;
    const start  = Date.now();

    if (height && Math.abs(change) < height) {
        duration = Math.abs(Math.floor(duration * change / container.clientHeight));
    }

    window.requestAnimationFrame(function frame() {
        const elapsed = Date.now() - start;
        const newPos  = easeBoth(elapsed, from, change, duration);

        container.scrollTop = Math.floor(newPos);

        if (elapsed <= duration && to !== from) {
            return window.requestAnimationFrame(frame);
        }

        container.scrollTop = to;

        if (typeof callback === "function") {
            return callback();
        }
    });
}
