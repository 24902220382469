import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import hover       from "hover";
import gems        from "elements/gems-mithril/gems";
import textTooltip from "text-tooltip";

import state       from "../../state.js";
import embed       from "../../embed.js";
import categories  from "../../categories.js";
import itemCodes   from "../item-codes/index.js";
import openBuyGems from "../buy-gems/openBuyGems";

import flyout      from "../flyout/index.js";
import mouseEvents from "./mouse-events.js";
import svg         from "../svg-icon/index.js";

import css from "./nav.css";

const i18n = window.gemstore_strings.nav;

export default {
    oninit(vnode) {
        vnode.state.buyHover = false;

        // when Gemstore is opened by Steam accounts in TP to buy gems
        if (state.openBuyGems) {
            state.openBuyGems = false;
            openBuyGems();
        }
    },

    view(vnode) {
        return m("div", { class : css.nav },
            m("div", { class : cx(css.balance, vnode.state.buyHover && css.buyHover) },
                m("div", { class : css.top },

                    // amount of gems
                    gems(game.stats.gems, { class : css.gems }),

                    m("button", {
                            class : state.features.billing.enabled ? css.buyGems : css.buyDisabled,

                            onclick : openBuyGems,

                            onmouseover : textTooltip(state.features.billing.tooltip,
                                () => (vnode.state.buyHover = true)
                            ),

                            onmouseout : textTooltip(
                                null,
                                () => (vnode.state.buyHover = false)
                            )
                        },
                        i18n.balance.buygems
                    )
                ),

                m("a", {
                        class : state.features.codes.enabled ? css.redeemCode : css.redeemDisabled,

                        onclick : () => {
                            if (!state.features.codes.enabled) {
                                return;
                            }

                            // once we can look up item code SKUs we'll allow Steam players here
                            if (GW2.config.features.itemCodesOnly) {
                                state.showPopup(() => m(itemCodes));

                                return;
                            }

                            // try iframing their site in NA bc CN is SLOW AF to dev on locally!
                            embed("codes");
                        },

                        onmouseover : textTooltip(state.features.codes.tooltip),

                        onmouseout : textTooltip(null)
                    },
                    i18n.balance.redeemcode
                )
            ),
            m("div", {
                    class      : css.sections,
                    onmouseout : hover(() => state.setFlyout(null))
                },

                categories.root.childCategories.map((cat) => {
                    const category = [ cat.id ];

                    return m("button", {
                            class : cx(
                                css.section,
                                (
                                    state.flyout &&
                                    state.flyout.indexOf(cat.id) !== -1
                                ) && css.hover,
                                (
                                    state.search.category &&
                                    state.search.category.indexOf(cat.id) !== -1
                                ) && css.active
                            ),

                            onclick : (e) => {
                                state.setFlyout(null);

                                mouseEvents.sectionClick(category, e);
                            },

                            onmouseover : hover(mouseEvents.sectionHover.bind(null, category))
                        },

                        m(svg, { class : css.icon, name : cat.name }),

                        m("p", { class : css.text }, categories[cat.id].displayname)
                    );
                }),

                m("button", {
                        class : cx(
                            css.section,
                            state.onHistory() && css.active
                        ),
                        onclick : state.purchases.length > 0 ?
                            (e) => {
                                state.setFlyout(null);
                                mouseEvents.sectionClick("/history", e);
                            } :
                            null,

                        onmouseover : state.purchases.length < 1 ?
                            textTooltip(i18n.noHistory) :
                            () => {
                                state.setFlyout("history");
                                state.flyoutResults = null;
                            },

                        onmouseout : (e) => {
                            textTooltip(null)(e);

                            // textTooltip sets e.redraw to false, but we need a redraw here
                            e.redraw = true;
                        }
                    },

                    m(svg, { class : css.icon, name : "purchases" }),

                    m("p", { class : css.text }, i18n.history)
                ),

                m(flyout)
            )
        );
    }
};
