import m  from "mithril";
import cx from "classnames";

import hover from "hover";

import prompt from "prompt/prompt";

import gw2Css    from "gw2.css";
import btnCss    from "button/button.css";
import promptCss from "prompt/prompt.css";

import { byGuid }  from "../../catalog/search.js";
import icon        from "../icon/index.js";
import itemTooltip from "../../util/item-tooltip.js";

import css from "./success.css";

const i18n = window.gemstore_strings;

export default {
    view(vnode) {
        const details = vnode.attrs.details;
        const item    = byGuid(details.info.guid);

        return m("div", prompt([
            m("h2", { class : promptCss.hd },
                i18n.purchasing.ok.title
            ),
            m("div", { class : cx(promptCss.bd, gw2Css.serif) },
                m("div", { class : css.hd },
                    icon(item, {
                        class : css.icon,
                        attrs : {
                            onmouseover : hover(itemTooltip.bind(null, item)),
                            onmouseout  : hover(itemTooltip.bind(null, item))
                        }
                    }),
                    i18n.purchasing.ok.info[details.success.instant ? "feature" : "item"]
                )
            ),
            m("div",
                m("button", {
                    class   : btnCss.btn,
                    onclick : vnode.attrs.hide
                }, i18n.purchasing.ok.button)
            )
        ], "", vnode.attrs.hide));
    }
};
