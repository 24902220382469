import object  from "object";
import sendLog from "send-log";

import state from "./state.js";

export default function log(extra) {
    return sendLog(object.merge({
        action         : "navigation", // overridden by extra for clicks/timer
        packageDetails : state.search.package,
        clickedItem    : state.clickedItem,
        searchTerm     : state.search.text,
        categories     : (state.search.category || []).join(","),
        group          : state.group
    }, extra));
}
