export default {
    Relation : {
        0 : "blocked",
        1 : "ignored",
        2 : "none",
        3 : "keep",
        4 : "follower",
        5 : "following",
        6 : "friend",

        blocked   : 0,
        ignored   : 1,
        none      : 2,
        keep      : 3,
        follower  : 4,
        following : 5,
        friend    : 6
    },
    
    Tag : {
        1 : "featured",
        2 : "splash",
        3 : "flash",
        4 : "float",

        featured : 1,
        splash   : 2,
        hot      : 3,
        flash    : 3,
        float    : 4
    },
    
    Category : {
        6  : "decorative",
        7  : "consumable",
        8  : "convenience",
        9  : "account",
        10 : "boosts",
        11 : "minipets",

        decorative  : 6,
        consumable  : 7,
        convenience : 8,
        account     : 9,
        boosts      : 10,
        minipets    : 11
    },
    
    SubCategory : {
        1  : "Tools",
        3  : "Outfits",
        4  : "Toys",
        5  : "Hats",
        6  : "Shirts",
        7  : "Pants",
        8  : "Shoes",
        9  : "Gloves",
        10 : "ArmorSkin",
        11 : "WeaponSkin",
        12 : "BackpackSkin",

        Tools        : 1,
        Outfits      : 3,
        Toys         : 4,
        Hats         : 5,
        Shirts       : 6,
        Pants        : 7,
        Shoes        : 8,
        Gloves       : 9,
        ArmorSkin    : 10,
        WeaponSkin   : 11,
        BackpackSkin : 12
    },

    Profession : {
        1 : "Guardian",
        2 : "Warrior",
        3 : "Engineer",
        4 : "Ranger",
        5 : "Thief",
        6 : "Elementalist",
        7 : "Mesmer",
        8 : "Necromancer",

        Guardian     : 1,
        Warrior      : 2,
        Engineer     : 3,
        Ranger       : 4,
        Thief        : 5,
        Elementalist : 6,
        Mesmer       : 7,
        Necromancer  : 8
    }
};
