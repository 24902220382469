import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import object from "object";
import gems   from "elements/gems-mithril/gems";
import btnCss from "button/button.css";

import state       from "../../state.js";
import purchase    from "../../purchase.js";
import openBuyGems from "../buy-gems/openBuyGems";

import icon from "../icon/index.js";

import contacts from "../contacts/index.js";
import success  from "./success.js";

import css from "./gifting.css";

const i18n = window.gemstore_strings.gifting;

function summary() {
    const orderSummary = {
        cost       : 0,
        quantity   : 0,
        recipients : 0
    };

    object.each(state.gifting.order, (gift) => {
        orderSummary.recipients += 1;
        orderSummary.quantity   += gift.count;
        orderSummary.cost       += gift.price;
    });

    return orderSummary;
}

export default {
    oninit : function() {
        state.gifting = {
            order    : {},
            success  : false,
            contacts : []
        };
    },

    view : function(vnode) {
        const orderSummary = summary();
        const item         = vnode.attrs.item;

        if (state.gifting.success) {
            return success(item);
        }

        return m("div", { class : css.gifting },
            m("div", { class : css.hd },
                m("h2", { class : css.title },
                    i18n.step.gifts.title
                ),
                m("div",
                    i18n.step.gifts.intro
                )
            ),
            m("div", { class : css.bd },
                m(contacts, item),

                m("div", { class : css.numRecipients },
                    `${i18n.step.success.summary.recipientslabel} ${orderSummary.recipients}`
                ),

                m("div", { class : css.summary },
                    m("div", { class : css.item },
                        icon(item, { class : css.icon }),
                        m("div",
                            item.name
                        )
                    ),
                    m("textarea[maxlength=500]", {
                        class       : css.message,
                        placeholder : i18n.step.gifts.messageplaceholder
                    }),
                    m("div", { class : css.orderSummary },
                        state.gifting.failure ?
                            m("div", { class : css.failure },
                                i18n.error.purchase.general
                            ) :
                            [
                                m("div", { class : css.qty },
                                    `${i18n.step.gifts.summary.quantitylabel} ${orderSummary.quantity}`
                                ),
                                m("div", { class : cx(game.stats.gems < orderSummary.cost && css.needGems) },
                                    `${i18n.step.gifts.summary.costlabel} `,
                                    gems(orderSummary.cost)
                                )
                            ]
                    )
                )

            ),
            m("div", { class : css.ft },
                m("button", {
                        class   : btnCss.btn,
                        onclick : state.hidePopup
                    },
                    window.gemstore_strings.flow.button.cancel
                ),
                m("button", {
                        class    : btnCss.btn,
                        disabled : orderSummary.recipients <= 0 || state.gifting.failure,
                        onclick  : () => {
                            const purchases = Object.keys(state.gifting.order).map((key) =>
                                state.gifting.order[key]
                            );

                            if (game.stats.gems < orderSummary.cost) {
                                openBuyGems();

                                return;
                            }

                            // Convert order map to an array
                            purchase(purchases, {
                                message : document.querySelector(`.${css.message}`).value
                            })
                            .then((result) => {
                                // Failures to top
                                result.purchases.sort((a) => !a.failure);

                                state.gifting.success = result;
                            })
                            .catch(() => {
                                state.gifting.failure = true;
                            })
                            .then(m.redraw);
                        }
                    },
                    i18n.step.gifts.nextbutton
                )
            )
        );
    }
};
