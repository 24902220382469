import m from "mithril";

import { update } from "../../catalog/search.js";

import carousel from "../../components/carousel/index.js";
import featured from "../../components/featured/index.js";

import css from "./home.css";

export default {
    oninit : update,

    view : () => m("div", { class : css.home },
        m(carousel, "splash"),
        m(featured)
    )
};
