import m  from "mithril";
import cx from "classnames";

import { game } from "native";

import popup from "popup";

import state from "../../state.js";

import nav       from "../nav/index.js";
import searchBar from "../search-bar/index.js";

/* program:!live */
import timePicker from "../time-picker/index.js";
/* /program:!live */

import purchasePrompt from "../purchase-prompt/index.js";

import css from "./layout.css";

export default {
    view : (vnode) => {
        const vip = game.stats.vip;

        return m("div", {
                class : cx(
                    css.gemstore,
                    vip && vip.active && "vip",
                    state.showingCached && css.cached
                )
            },

            m(nav),
            m(searchBar),

            vnode.children,

            // Mithril popups need to be a function so they redraw
            popup(typeof state.popup === "function" ? state.popup() : state.popup, null, state.hidePopup),

            /* program:!live */
            state.timePicker && m(timePicker),
            /* /program:!live */

            m(purchasePrompt)
        );
    }
};
