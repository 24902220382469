import { game } from "native";

import object    from "object";
import getModule from "native-module";

import enums from "../../enums.js";
import state from "../../state.js";

const playerModule = getModule("gw2/player");

export default function() {
    state.gifting.contacts = [];

    return Promise.all([
        game.call("AccountGetContacts"),
        game.call("CharacterGetParty"),
        game.call("AccountGetGuilds"),
        playerModule
    ])
    .then(function(results) {
        const contacts = {};
        const player   = results[3];

        function addContact(contact, contactType) {
            // Can't gift to yourself
            if (contact.id === player.portalUserId) {
                return;
            }

            if (!contacts[contacts.id]) {
                contacts[contact.id] = {
                    id    : contact.id,
                    name  : contact.name.replace(/^:/, ""),
                    types : [ ]
                };
            }

            contacts[contact.id].types.push(contactType);
        }

        results = {
            friends : results[0],
            party   : results[1],
            guilds  : results[2]
        };

        results.friends.forEach((friend) => {
            const relation = enums.Relation[friend.relation];

            if (relation === "following" || relation === "friend") {
                addContact(friend, "friend");
            }
        });

        (results.party.members || []).forEach((partyMember) =>
            addContact(partyMember, "party")
        );

        results.guilds.forEach((guild) =>
            guild.members.forEach((guildMember) =>
                addContact(guildMember, "guild")
            )
        );

        object.each(contacts, (contact) => state.gifting.contacts.push(contact));

        state.gifting.contacts.sort((a, b) => a.name.localeCompare(b.name));
    });
}
