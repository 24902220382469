import m      from "mithril";
import object from "object";

import css from "./icon.css";

export default function icon(item, extra) {
    if (!item) {
        return null;
    }

    if (!extra) {
        extra = {};
    }

    const attrs = object.merge({
        class : extra.containerClass || (extra.expand ? css.expandedContainer : css.container)
    }, extra.attrs);

    // TODO : this is for backcompat - should just have this passed in as part of attrs
    if (extra.onclick) {
        attrs.onclick = extra.onclick;
    }

    // pass in extra.expand to make the tooltip hitbox expand to the parent element
    return m("div", attrs,
        m("img", {
            class    : extra.class || css.icon,
            onupdate : function(vnode) {
                // Don't fade images in if they're cached
                if (vnode.dom.complete) {
                    vnode.dom.style.webkitTransition = "none";
                }
            },

            src    : item.images[extra.imageSize || "large"],
            onload : function fadeIn(e) {
                e.redraw           = false;
                this.style.opacity = 1;
            },
            onerror : function(e) {
                if (extra.imageSize === "splash") {
                    item.images.splash = e.currentTarget.src = `${GW2.imageBase}/44e4c6dd_splash.jpg`;

                    return;
                }

                item.images.large = e.currentTarget.src = `${GW2.imageBase}/44e4c6dd_large.png`;
            }
        })
    );
}
