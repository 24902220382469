import { game } from "native";

import object from "object";

import { get as getTime } from "./time.js";

const localState = {
    gotPersisted : false, // assume item mustHave is suppressed, until persisted data is returned (race condition)
    mustHave     : {}
};

function getTimestamp(dateStr) {
    return (new Date(dateStr)).getTime();
}

/**
 * Set suppressed mustHave to persistent datastore
 */
function setPersistedSuppressed() {
    game.import("gw2/datastore").then(ds => {
        ds.store("mustHave", localState.mustHave, { persist : true });
    });
}

/**
 * Get persisted suppressed and stick on state.
 */
export function getPersistedSuppressed() {
    game.import("gw2/datastore").then(ds => {
        ds.prepare().then(() => {
            localState.gotPersisted = true;

            localState.mustHave = object.merge(localState.mustHave, ds.mustHave);
        });
    });
}

/**
 * Suppress item musthave until end date
 * @param {Object} item - gemstore item
 */
export function suppress({ gem_store_item_id : guid, tags }) {
    const suppressedDate = localState.mustHave[guid];

    // already suppressed
    if (suppressedDate && suppressedDate === tags.musthave.end) {
        return;
    }

    localState.mustHave[guid] = tags.musthave.end;

    setPersistedSuppressed();
}

/**
 * Check if an item musthave is suppressed
 * @param {string} guid - gemstore item id
 */
export function isSuppressed(guid) {
    const suppressedDate = localState.mustHave[guid];

    if (!localState.gotPersisted) {
        return true;
    }

    if (!suppressedDate) {
        return;
    }

    return getTimestamp(suppressedDate) > getTime();
}

/**
 * Clear all suppressed musthave (for testing)
 */
export function clearSuppressed() {
    localState.mustHave = {};

    setPersistedSuppressed();
}

window.clearSuppressed = clearSuppressed;
// window.mustHave = localState;
