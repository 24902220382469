import m from "mithril";

import { game } from "native";

import object from "object";

import getModule from "native-module";

import state from "../state.js";

import { set as setTime, get as getTime } from "./time.js";

import getCatalog  from "./get-catalog.js";
import loadCatalog from "./load.js";
import cached      from "./cached.js";
import { byGuid }  from "./search.js";

import images from "../util/images.js";

// cache if previewable or not
const previewableItems = {};

const MAX_JITTER           = game.isMockEnv ? 3000 : 500; // low values make Chrome very unhappy
const CATALOG_REFRESH_TIME = 5000;
const REDRAW_TIME          = 1000;

function previewable() {
    if (game.isMockEnv) {
        // 2k promises/requests kills the browser
        return;
    }

    // Add previewable to items through a series of shitty promises
    getModule("gw2/ui/paperdoll").then((module) => {
        object.each(window.gemstoreCatalog, (catItem, guid) => {
            const item = state.catalog[guid] || catItem;

            // early out if cached
            if (guid in previewableItems) {
                return previewableItems[guid];
            }

            // Verify that the package has at least one previewable element
            if (item && item.isPackage && !item.soldOut) {
                // Throttling w/ setTimeout because tons of promises resolving at once blocks everything
                return setTimeout(() => Promise.all(
                    item.contents.map((pkgItem) => {
                        const deliverable = byGuid(pkgItem.guid);

                        return (deliverable &&
                                typeof item.dataId === "number" &&
                                typeof deliverable.dataId === "number" &&
                                !deliverable.isContent
                            ) ?
                            module.canPreview(deliverable.dataId)
                                .then((result) => {
                                    // Set previewable for contents
                                    window.gemstoreCatalog[pkgItem.guid].previewable = deliverable.previewable = result;

                                    // Set previewable for package container
                                    if (result) {
                                        item.previewable = catItem.previewable = true;
                                    }

                                    return result && module.canNativePreview(item.dataId);
                                }) :
                            false;
                        })
                    )
                    .then((results) => {
                        state.catalog[guid].previewable = catItem.previewable = results.some(Boolean);
                    }),

                    Math.random() * MAX_JITTER
                );
            }

            // don't preview:
            // - nothing items
            // - content type items (LW episodes)
            // - things that are sold out and not part of a package
            if (!item.dataId || item.isContent || (item.soldOut && state.inPackage.indexOf(guid) < 0)) {
                return;
            }

            // Item checks are simpler
            // Throttling w/ setTimeout because tons of promises resolving at once blocks everything
            return setTimeout(() =>
                module.canPreview(item.dataId)
                    .then((result) => {
                        previewableItems[guid] = catItem.previewable = Boolean(result);

                        if (state.catalog[guid]) {
                            state.catalog[guid].previewable = Boolean(result);
                        }

                        return previewableItems[guid] && module.canNativePreview(item.dataId);
                    })
                    .then((result) => {
                        state.catalog[guid].nativePreviewable = catItem.nativePreviewable = Boolean(result);
                    }),

                Math.random() * MAX_JITTER
            );
        });
    });
}

// Poll for updates every 5s
// This also cleans up expired countdown items sitting at 00:00:00 for a bit
function updates() {
    return getCatalog()
        .then(loadCatalog)
        .then(previewable)
        .then(() => {
            m.redraw();

            window.setTimeout(updates, CATALOG_REFRESH_TIME);
        })
        // Wait a bit longer after an error
        .catch(() => window.setTimeout(updates, CATALOG_REFRESH_TIME * 2));
}

export default function setup() {
    // Initial time, we'll update to an accurate server time after getting catalog
    setTime(GemStore.gsOverrideTime || Date.now());

    if (GemStore.gsOverrideTime) {
        state.timePicker = true;
    }

    game.call("SetLoading", true);

    return cached()
        .catch(getCatalog)
        .then(loadCatalog)
        .then(() => {
            // Add some extra properties to items returned by the static catalog file,
            // but not returned by GetCatalog so we don't always have to check for them
            object.each(window.gemstoreCatalog, (item, guid) => {
                if (state.catalog[guid]) {
                    return;
                }

                item.images     = images(item);
                item.quantities = [];
                item.quantity   = {};
                item.isItem     = item.type === "item";
                item.callout    = {};
            });
        })
        .then(previewable)
        .then(() => {
            // If initial data was from server don't re-request for 5s
            if (!state.showingCached) {
                setTimeout(updates, CATALOG_REFRESH_TIME);
            } else {
                // Using cached data so need to get deltas asap
                updates();
            }

            // redraw every second so all the countdown timers can update
            window.setInterval(m.redraw, REDRAW_TIME);

            /* program:!live */
            // Timepicker: Keep the override time up to date so any sts requests can just include it
            if (GemStore.gsOverrideTime) {
                window.setInterval(() => {
                    GemStore.gsOverrideTime = (new Date(getTime()))
                        .toISOString()
                        .replace(/\.\d{3}/, "");
                }, 1000);
            }
            /* /program:!live */

            state.resultsChanged = true;

            game.call("SetLoading", false);
        });
}
