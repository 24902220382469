import { game } from "native";

import gemstore from "./gemstore/index.js";

game.initSession();

game.initNative().then(gemstore);

window.GemStore = {
    i18n : window.gemstore_strings
};
