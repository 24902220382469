import m from "mithril";

import textTooltip from "text-tooltip";

import state   from "../../state.js";
import preview from "../../util/show-preview.js";

import svg from "../svg-icon/index.js";

import giftingLink from "./gifting-link.js";

import css from "./links.css";

const i18n = window.gemstore_strings;

export default {
    view(vnode) {
        const args = vnode.attrs;
        const item = args.item;

        if (args.package && !item.previewable) {
            return null;
        }

        return m("div", { class : args.class || css.links },

            !args.package && item.isPackage ?
                m("button", {
                        class : css.link,

                        onclick : (e) => {
                            if (state.search.package) {
                                // Back to category
                                return m.route.set(`/category/${state.search.category.join("/")}?guid=${item.guid}`);
                            }

                            if (item.isPackage) {
                                e.stopPropagation();
                                state.highlightItem(item, state.search.category);
                            }
                        },

                        onmouseover : textTooltip(state.search.package ?
                            i18n.tooltip.back :
                            i18n.tooltip.packageDetails
                        ),
                        onmouseout : textTooltip(null)
                    },
                    m(svg, {
                        class : css.icon,
                        name  : state.search.package ? "package-off" : "package"
                    })
                ) :
                null,

            item.previewable ?
                m("button", {
                        class : css.link,

                        onclick : (e) => {
                            // Prevent event bubbling because they might navigate away from where we want
                            e.stopPropagation();

                            preview(item, document.querySelector(args.selector));
                        },

                        onmouseover : textTooltip(i18n.tooltip.preview),
                        onmouseout  : textTooltip(null)
                    },
                    m(svg, {
                        name  : "preview",
                        class : css.icon
                    })
                ) :
                null,

            m(giftingLink, args)
        );
    }
};
