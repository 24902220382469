import m from "mithril";

import hover from "hover";

import state       from "../../state.js";
import { byGuid }  from "../../catalog/search.js";
import { visible } from "../../util/character.js";
import preview     from "../../util/show-preview.js";
import itemTooltip from "../../util/item-tooltip.js";

import links    from "../item-links/index.js";
import icon     from "../icon/index.js";
import gridItem from "../item/index.js";

import gridCss     from "../grid/grid.css";
import gridItemCss from "../item/item.css";
import css         from "./package-item.css";

function packageItems(items) {
    return m("ul", { class : css.items, key : "packageItems" },
        items.map((thing) => {
            const guid = thing.guid;
            const item = byGuid(guid);

            if (!item) {
                return null;
            }

            return m("li", {
                    class          : css.item,
                    "data-pkgitem" : guid,
                    onclick        : item.previewable && ((e) => preview(item, e.currentTarget.parentNode))
                },
                m("div", { class : css.content },
                    icon(item, {
                        class          : css.icon,
                        containerClass : item.previewable ? gridItemCss.previewable : gridItemCss.iconContainer,
                        attrs          : {
                            onmouseover : hover(itemTooltip.bind(null, item)),
                            onmouseout  : hover(itemTooltip.bind(null, item))
                        }
                    }),
                    m("div", { class : css.bd },
                        m("h2", { class : css.name },
                            item.name
                        )
                    ),
                    thing.quantity > 1 && m("div", { class : css.quantity },
                        m.trust(`&times; ${thing.quantity}`)
                    )
                ),
                m(links, {
                    item,
                    class    : css.links,
                    package  : true,
                    selector : `[data-pkgitem="${guid}"]`
                })
            );
        })
    );
}

export default {
    view : () => {
        const pkg      = byGuid(state.search.package);
        const items    = pkg && pkg.included;
        const excluded = pkg && pkg.excluded;

        // When reloading from the debugger, there are no items so don't error
        if (!items && !excluded) {
            return null;
        }

        return m("div", { class : visible() ? gridCss.containerSingle : gridCss.container },
            m("ul",
                m(gridItem, {
                    item : pkg
                })
            ),
            m("div", { class : css.contains },
                window.gemstore_strings.items.item.package.contains
            ),
            m("div", { class : css.packageList },
                packageItems(items),

                excluded.length ? m("div", { class : css.excluded },
                    m("p",
                        window.gemstore_strings.items.item.package.warning
                    ),
                    packageItems(excluded)
                ) :
                null
            )
        );
    }
};
