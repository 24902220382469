import m from "mithril";

import { game } from "native";

import gems from "elements/gems-mithril/gems";

import state       from "../../state.js";
import { parents } from "../../categories.js";
import { button }  from "../item-buttons/index.js";
import icon        from "../icon/index.js";

import css from "./history-item.css";

export default function(item) {
    const clickable   = !item.soldOut && item.active;
    const canPurchase = clickable && item.quantities.some((qty) =>
            qty.count === item.purchased.count && qty.price === item.purchased.price
        );

    return m("li", {
            class   : clickable ? css.clickable : css.item,
            onclick : () => {
                const hasParentCat = item.categories.find(cat => parents(cat));

                if (!hasParentCat || !clickable) {
                    return;
                }

                state.setFlyout(null);
                state.highlightItem(item, null, { src : "history" });
            }
        },
        icon(item, { class : css.icon }),
        m("div", { class : css.details },
            m("div", { class : css.name }, item.name),
            m("div", { class : css.date }, (new Date(item.created)).toLocaleDateString(game.stats.language))
        ),
        m("div", { class : css.price },
            (canPurchase) ?
                button(item, item.purchased, "history") :
                m("div", { class : css.priceOnly },
                    item.purchased.count > 1 && m.trust(`${item.purchased.count} /&nbsp;`),
                    gems(item.purchased.price)
                )
        )
    );
}

