import m from "mithril";

import state from "../../state.js";
import css   from "./buy-gems.css";

// const dummyData = {
//     steamId     : "210921021012021",
//     type        : "gems",
//     orderId     : "160107208558550014",
//     transId     : "2653140535650305248",
//     amount      : 800,
//     totalPretty : "$10.00",
//     items : [{
//         itemid     : 101,
//         qty        : 1,
//         amount     : 1000,
//         vat        : 0,
//         itemstatus : "Succeeded",
//         pretty     : {
//             amount : "$10.00",
//             vat    : "$0.00"
//         }
//     }]
// };

export default {
    view(vnode) {
        const { steamId, orderId, transId, amount, totalPretty, items } = vnode.attrs;

        const i18n = window.gemstore_strings.buyGems.success;

        return m("div", { class : css.container },
            m("div", { class : css.innerContent },
                m("h1", i18n.header),
                m("p", i18n.intro)
            ),

            m("div", { class : css.details },
                m("div",
                    m("h2", i18n.summary.header),

                    m("div", { class : css.item },
                        m("div", { class : css.detailGems }, i18n.gemsAmount.replace("{amount}", amount)),
                        m("div", { class : css.price }, items[0].pretty.amount)
                    ),

                    m("div", { class : css.tax },
                        m("div", i18n.summary.tax),
                        m("div", { class : css.price }, items[0].pretty.vat)
                    ),

                    m("div", { class : css.total },
                        m("div", i18n.summary.total),
                        m("div", { class : css.price }, totalPretty)
                    )
                ),

                m("div",
                    m("h2", i18n.payment.header),
                    m("p", i18n.payment.steam.replace("{last4}", steamId.slice(steamId.length - 4))),

                    m("h2", i18n.transId),
                    m("p", transId),

                    m("h2", i18n.orderId),
                    m("p", orderId)
                )
            ),

            m("div", { class : css.buttons },
                m("button", {
                    class : css.button,
                    onclick() {
                        state.hidePopup();
                    }
                }, i18n.done)
            )
        );
    }
};
