import m  from "mithril";
import cx from "classnames";

import hover from "hover";
import gems  from "elements/gems-mithril/gems";

import state from "../../state.js";
import mSub  from "../../util/sub.js";

import getContacts from "./get-contacts.js";

import css from "./contacts.css";

const i18n         = window.gemstore_strings.gifting;
const MAX_CONTACTS = 750;

export default {
    oninit : function() {
        const self = this;

        self.filterHovered     = false;
        self.hoveredQuantities = null;

        self.filter = {
            text : "",
            type : "all"
        };

        self.filterHover = hover(function(e) {
            self.filterHovered = e.type === "mouseover";
        });

        self.quantitiesHover = function(contactId, e) {
            // TODO: this is hacky/fragile, find a better way to always show dropdown items
            // w/in overflow:scroll container
            self.inverseMenu       = e.screenY > 455;
            self.hoveredQuantities = contactId;
        };

        self.addToOrder = function(guid, contact, quantity) {
            state.gifting.failure = false;

            if (quantity === null) {
                delete state.gifting.order[contact.id];

                return;
            }

            // Formatting to match what the purchase module expects
            state.gifting.order[contact.id] = {
                guid      : guid,
                recipient : contact,
                count     : quantity.count,
                price     : quantity.price,
                discount  : (quantity.regular_price || quantity.price) - quantity.price
            };
        };

        self.filterContacts = function() {
            self.filteredContacts = state.gifting.contacts.filter(function(contact) {
                if (state.gifting.order[contact.id]) {
                    return true;
                }

                if (self.filter.type !== "all" && contact.types.indexOf(self.filter.type) === -1) {
                    return false;
                }

                if (self.filter.text && contact.name.toLowerCase().indexOf(self.filter.text) === -1) {
                    return false;
                }

                return true;
            });

            if (self.filteredContacts.length > MAX_CONTACTS) {
                self.filteredContacts = self.filteredContacts.slice(0, MAX_CONTACTS);
            }
        };

        self.filteredContacts = [];

        getContacts().then(function() {
            self.filterContacts();
            m.redraw();
        });
    },
    view : function(vnode) {
        return m("div", { class : css.contacts },
            m("div", { class : css.search },
                m("input[type=search][required]", {
                    class       : css.searchInput,
                    placeholder : i18n.step.gifts.contacts.searchplaceholder,
                    oninput     : (e) => {
                        vnode.state.filter.text = e.currentTarget.value.toLowerCase();
                        vnode.state.filterContacts();
                    }
                }),
                m("div", {
                        class       : css.filters,
                        onmouseover : vnode.state.filterHover,
                        onmouseout  : vnode.state.filterHover
                    },
                    m("div", { class : css.filterIcon }),
                    m("ul", { class : vnode.state.filterHovered ? css.visibleFilterList : css.filterList },
                        [ "all", "friend", "guild", "party" ].map((filterType) => m("li", {
                                class   : filterType === vnode.state.filter.type ? css.selectedFilter : css.ddItem,
                                onclick : function() {
                                    vnode.state.filter.type   = filterType;
                                    vnode.state.filterHovered = false;
                                    vnode.state.filterContacts();
                                }
                            },
                            i18n.step.gifts.contacts.filters[filterType]
                        ))
                    )
                )
            ),
            m("ul", { class : css.contactList },
                vnode.state.filteredContacts.map((contact) => {
                    const gift = state.gifting.order[contact.id];

                    let selectedQuantity = vnode.attrs.quantities[0];

                    if (gift) {
                        vnode.attrs.quantities.some((qty) => {
                            if (qty.count === gift.count) {
                                selectedQuantity = qty;

                                return true;
                            }

                            return false;
                        });
                    }

                    return m("li", {
                            class   : gift ? css.selected : css.contact,
                            onclick : () =>
                                vnode.state.addToOrder(vnode.attrs.guid, contact, state.gifting.order[contact.id] ? null : selectedQuantity)
                        },
                        m("div", { class : css.name },
                            contact.name
                        ),
                        m("div", {
                                class       : css.quantities,
                                onmouseover : hover(vnode.state.quantitiesHover.bind(null, contact.id)),
                                onmouseout  : hover(vnode.state.quantitiesHover.bind(null, null))
                            },

                            m("div", { class : cx(vnode.attrs.quantities.length > 1 && css.multipleQuantities) },
                                mSub(i18n.price, {
                                    amount : selectedQuantity.count,
                                    price  : gems(selectedQuantity.price)
                                })
                            ),

                            (vnode.state.hoveredQuantities && vnode.attrs.quantities.length > 1) ?
                                m("ul", {
                                        class : cx(
                                            vnode.state.hoveredQuantities === contact.id ? css.quantityListShow : css.quantityList,
                                            vnode.state.inverseMenu && css.quantityListInverse
                                        )
                                    },
                                    vnode.attrs.quantities.map((quantity) => m("li", {
                                            class   : css.quantity,
                                            onclick : (e) => {
                                                vnode.state.addToOrder(vnode.attrs.guid, contact, quantity);
                                                vnode.state.hoveredQuantities = null;

                                                e.stopPropagation();
                                            }
                                        },
                                        mSub(i18n.price, {
                                            amount : quantity.count,
                                            price  : gems(quantity.price)
                                        })
                                    ))
                                ) : ""
                        )
                    );
                }),
                vnode.state.filteredContacts.length === MAX_CONTACTS && m("li", { class : css.tooMany },
                    i18n.step.gifts.contacts.more
                ),
                vnode.state.filteredContacts.length === 0 && m("li", { class : css.noContacts },
                    i18n.step.gifts.contacts.noresults
                )
            )
        );
    }
};
