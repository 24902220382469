import m from "mithril";

import { game } from "native";

import css from "./gfn.css";

const build = m.buildQueryString || m.route.buildQueryString;

const gfn = {
    oninit() {
        game.call("SetLoading", false);
    },

    view(vnode) {
        const i18n = window.gemstore_strings; // temp for upgrade lang, can move outside component scope later

        const { embedInfo, showPopup } = vnode.attrs;

        return m("div", { class : css.gfnContainer },
            m("h1", i18n.gfn[embedInfo.type].title),
            m("h2", m.trust(i18n.gfn[embedInfo.type].subtitle)),
            m("div", { class : css.gfnInfo },
                m("img", { src : embedInfo.qr, class : css.gfnQr  }),
                m("div", { class : css.gfnSpacer },
                    m("hr"),
                    m("p", i18n.gfn.or),
                    m("hr")
                ),
                m("div", { class : css.gfnEmail },
                    embedInfo.emailed || vnode.state.clicked ?
                        [
                            m("img", { class : css.gfnEnvelope, src : "https://2gemstore.staticwars.com/img/mail_icon.svg" }),
                            m("p", { class : css.gfnGetEmail }, i18n.gfn.checkEmail)
                        ] :
                        m("button", {
                                class : css.gfnEmailBtn,
                                onclick() {
                                    vnode.state.clicked = true;

                                    m.request({
                                        url : `/ws/email/${embedInfo.type}?${build({
                                            s    : game.stats.sessionId,
                                            lang : game.stats.language.slice(0, 2)
                                        })}`
                                    })
                                    .then((res) => {
                                    // TODO: this is gross, destroy by refactoring popup to do this the correct way
                                    // Future dev: the correct way is not having to do this at all
                                    // having popup render components (passing mithril objects instead of m()
                                    // components to popup) will make it so we don't have to do redraw hacks like this
                                        if (showPopup) {
                                            showPopup(() => m(gfn, { embedInfo : res }));
                                        }
                                    });
                                }
                            },
                            i18n.gfn[embedInfo.type].email
                        )
                )
            ),
            m("p", i18n.gfn.unavailable),
            m("p", m.trust(i18n.gfn.moreInfo))
        );
    }
};

export default gfn;
