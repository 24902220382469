import m from "mithril";

import state      from "../../state.js";
import { search } from "../../catalog/search.js";

export default {
    sectionClick : function(category, e) {
        e.redraw = false;
        e.preventDefault();
        e.stopPropagation();

        if (category === "/history") {
            m.route.set("/history");

            return;
        }

        m.route.set(`/category/${category.join("/")}?src=${category.length > 1 ? "flyout" : "nav"}`);
    },

    sectionHover : function(category) {
        // Compare the most specific category in the list to see if there's no change
        if (state.flyout && state.flyout.slice(-1)[0] === category.slice(-1)[0]) {
            return;
        }

        state.setFlyout(category);

        // Set flyout results
        const results = search({ category : category });

        state.flyoutResults = {
            thumbnails : results.slice(0, 9),
            // Items already sorted in recommendation order, so use the first
            featured   : results[0]
        };
    }
};
