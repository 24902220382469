function noop(e) {
    e.redraw = false;
}

// Simulate mouseenter/mouseleave, chromium 28 doesn't support these events yet
export default function hover(cb) {
    if (typeof cb !== "function") {
        return noop;
    }

    return function(e) {
        if (
            e.relatedTarget &&
            (
                e.relatedTarget === e.currentTarget ||
                (
                    // eslint-disable-next-line no-bitwise
                    e.currentTarget.compareDocumentPosition(e.relatedTarget) &
                    Node.DOCUMENT_POSITION_CONTAINED_BY
                )
            )
        ) {
            return noop(e);
        }

        return cb(e);
    };
}
