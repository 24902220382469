import getModule from "native-module";

import { byGuid } from "../catalog/search.js";
import keys       from "./keys.js";

const colors        = {
        noCallout   : "F5B24A",
        sale        : "F5B24A",
        endingTime  : "F74500",
        endingLimit : "F74500",
        expiring    : "71A3D8",
        new         : "71A3D8",
        white       : "FFFFFF"
    };
const tooltipModule = getModule("gw2/ui/tooltip");

function prices(quantities) {
    return quantities.map((qty) => {
        // Avoiding confusing nested template strings & linter complaints
        const count = qty.count > 1 ? `${qty.count}/` : "";

        return `<c=#71A3D8>[${count}${qty.price} ${GemStore.i18n.tooltip.gems}]</c>`;
    })
    .join("  ");
}

function addendums(cats) {
    const text = (cats || []).map((cat) => cat.addendum)
        .filter(Boolean)
        .join("\n");

    return text || "";
}

function topCallout(callout) {
    return callout.text ? `<c=#${colors[callout.type]}>${callout.text}</c>\n` : "";
}

function description(item) {
    const desc = item.gem_store_description || item.description || "";

    return desc ? `${desc}` : "";
}

function bottomCallout(callout) {
    const details = callout.details || (callout.getDetails && callout.getDetails());

    return details ? `\n<c=#${colors[callout.type]}>${details}</c>` : "";
}

function contents(thing, color) {
    const qty = thing.quantity > 1 ? `(${thing.quantity}) ` : "";

    return `<c=#${colors[color]}>• ${qty}${byGuid(thing.guid).name}</c>`;
}

function packageTooltip(item) {
    const sections = [];

    let included = item.included,
        excluded = item.excluded;

    sections.push(`<c=#FFE51F>${item.name}</c>`);
    sections.push(topCallout(item.callout) + prices(item.quantities) + bottomCallout(item.callout));
    sections.push(description(item));

    included = included.map((thing) => contents(thing, "white"));

    if (included.length) {
        sections.push(`${GemStore.i18n.items.item.package.contains}\n${included.join("\n")}`);
    }

    excluded = excluded.map((thing) => contents(thing, "noCallout"));

    if (excluded.length) {
        sections.push(`<c=#F5B24A>${GemStore.i18n.items.item.package.warning}</c>\n${excluded.join("\n")}`);
    }

    sections.push(addendums(item.categories));

    return sections.filter(Boolean).join("\n\n");
}

export default function itemTooltip(item, e) {
    // Don't redraw for native tooltips
    e.redraw = false;

    tooltipModule.then((tooltip) => {
        if (!item || e.type === "mouseout") {
            return tooltip.hide();
        }

        if (item.isPackage) {
            return tooltip.showText(packageTooltip(item));
        }

        if (!item.isItem) {
            return tooltip.showText(item.description || "");
        }

        keys.inject(item.dataId);

        const body = [
                topCallout(item.callout) + prices(item.quantities) + bottomCallout(item.callout),
                description(item),
                addendums(item.categories)
            ]
            .filter(Boolean)
            .join("\n\n");

        // NOTE: Using .itemGuid here is important, because .guid is the GEM STORE guid
        // not the delivered item guid, and the tooltip module has no clue what to do with that
        // and crashes the client. So just use .itemGuid ok?
        return tooltip.showItem(item.itemGuid, {
            textNameOverride       : item.name,
            textDescHeaderOverride : "",
            textDescOverride       : `\n${body}\n`
        });
    });
}
