import m from "mithril";

import state from "../../state";
import embed from "../../embed.js";

import buyGems from "./index.js";

/**
 * Show correct buy gems for account type, Steam or first party
 */
export default function openBuyGems() {
    if (!state.features.billing.enabled) {
        return;
    }

    if (state.isSteamUser()) {
        state.showPopup(() => m(buyGems));

        return;
    }

    embed("gems");
}
