import m             from "mithril";
import { interpret } from "@xstate/fsm";

import css from "./buy-gems.css";

import spinner        from "spinner/spinner";
import gemMaintenance from "maintenance/maintenance";
import gemSuccess     from "./gemSuccess";

import createBuyGemsMachine from "./lib/machine";
import invoke               from "./lib/invoke";

const i18n = window.gemstore_strings.buyGems;

let gemsService, gemsState, gemSubscription;

export default {
    oninit() {
        const machine = createBuyGemsMachine();

        gemsService     = interpret(machine);
        gemSubscription = gemsService.subscribe(_state => {
            gemsState = _state;

            // fsm doesn't support invoke actions
            invoke(gemsState, gemsService);
        });

        gemsService.start();
    },

    onremove() {
        gemSubscription.unsubscribe();
        gemsService.stop();
    },

    view() {
        const { gems, loading } = gemsState.context;

        if (gemsState.value === "success") {
            return m(gemSuccess, gemsState.context);
        }

        if (gemsState.value === "error") {
            return m(gemMaintenance, gemsState.context);
        }

        return m("div", { class : css.container },

            m("div", { class : css.innerContent },
                m("h1", i18n.list.header),

                gemsState.context.cancelled ?
                    [
                        m("p", i18n.error.steamAuth),
                        m("p", { class : css.orderId },
                            i18n.error.orderId,
                            gemsState.context.orderId
                        )
                    ] :
                    m("p", i18n.list.intro),

                m("div", { class : css.listing },
                    gems.length ?
                        gems.map(({ amount, prettyPrice }, idx) =>
                            m("button", {
                                    "data-gems" : `gems-${amount}`,
                                    class       : css[`gems-${amount}`],
                                    onclick() {
                                        gemsService.send({ type : "NEXT", idx });
                                    }
                                },

                                m("div", i18n.list.gemsAmount.replace("{amount}", amount)),

                                prettyPrice
                            )
                        ) :
                        m("div", { class : css.spinner }, m(spinner))
                ),

                m("div", { class : css.footer },
                    m("p", i18n.list.paymentDesc)
                )
            ),

            loading ?
                m("div", { class : css.spinner }, m(spinner)) :
                null
        );
    }
};
