import { game } from "native";

import accountStatus from "account-status";

export default function upsellType() {
    // Show the upsell in china until the user has the ultimate edition
    const account = accountStatus();
    const china   = game.buildInfo.gameCode === "gw2cn";
    const expac2  = account.expansions[china ? "expac2Deluxe" : "expac2"];
    const expac3  = account.expansions[china ? "expac3Deluxe" : "expac3"];
    const expac4  = account.expansions[china ? "expac4Deluxe" : "expac4"];
    const expac5  = account.expansions[china ? "expac5Deluxe" : "expac5"];

    if (!GW2.config.features.upgrade) {
        return null;
    }

    // simplify for now
    // China prioritizes showing EoD until user has deluxe & never shows combo
    // if (china) {
    //     // prioritize JW
    //     if (!expac5) {
    //         return "upgrade_5";
    //     }

    //     if (!expac3) {
    //         return "upgrade_3";
    //     }

    //     if (!expac4) {
    //         return "upgrade_4";
    //     }

    //     return "upgrade_p4f";
    // }

    // prioritize JW
    if (!expac5 && GW2.config.features.exp5) {
        return "upgrade_5";
    }

    // no pof - show combo
    if (!expac2) {
        return "upgrade_p4f_3";
    }

    // has HOT/POF - show EOD only
    if (!expac3) {
        return "upgrade_3";
    }

    // otherwise soto
    if (!expac4) {
        return "upgrade_4";
    }

    // None
    return null;
}
